import {
  FETCH_TRANSACTIONS_BEGIN,
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_FAILURE
} from '../redux-actions/transactions.js';

const initialState = {
  transactions: [],
  showTransactionsModal: [false],
  loading: false,
  error: null,
  isLoaded: false,
  timestamp: 0
};

export default function transactionsReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_TRANSACTIONS_BEGIN:

      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_TRANSACTIONS_SUCCESS:
      state.showTransactionsModal[0] = true    
      const a = {
        ...state,
        loading: false,
        transactions: action.payload.transactions,
        isLoaded: true,
        timestamp: new Date().getTime()
      };
      return a;

    case FETCH_TRANSACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        transactions: [],
        isLoaded: false,
        timestamp: new Date().getTime()
      };

    default:
      return state;
  }
}