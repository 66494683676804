import React, { Component } from 'react';
import './Downloads.css'
import { Button, Progress } from 'reactstrap';
import Helpers from '../Helpers.js';
import Popup from '../components/Popup.js';
import FileDetailsPopup from '../components/FileDetailsPopup.js';
import { Box, Tab, Grid } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import CustomButton from '../components/CustomButton.js';

class Downloads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      err: [],
      selectedPatch: null,
      selectedPatchType: null,
      versions: [],
      showFilePopup: [false],
      selectedUrl: null,
      showPopup: [false],
      popupText: '',
      jumpTo: [props.match.params.name],
      myTab: props.match.params && props.match.params.type == 'patches' ? '1' : '0'
    }

    this.renderPatch = this.renderPatch.bind(this);
    this.routeToFile = this.routeToFile.bind(this);
    this.onDownloadClick = this.openFileDetails.bind(this);
    this.onInfoClick = this.onInfoClick.bind(this);
    this.onFileDownload = this.onFileDownload.bind(this);
    this.onFileDetailsClosed = this.onFileDetailsClosed.bind(this);
    this.reportDownload = this.reportDownload.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
  }

  renderPatch(patch, type) {
    const id = 'id-' + patch.name;
    const banner = patch.banner_url ? patch.banner_url : require('../res/cp.png');
    const last_updated = (patch.data && patch.data.length > 0) ? Helpers.convertDate(patch.data[0].date_created) : 'N/A';
    const last_version = (patch.data && patch.data.length > 0) ? patch.data[0].version_name : '';
    const more_info = patch.web_url ? (
      <CustomButton style={{'display': 'inline-block', 'marginRight': '10px'}} text='MORE INFO' icon={require('../res/info.png')} onClick={() => this.onInfoClick(patch.web_url)} />
    ) : null;
    const discuss_url = patch.discuss_url ? (
      <CustomButton style={{'display': 'inline-block', 'marginRight': '10px'}} text='Discuss!' icon={require('../res/discord.png')} onClick={() => this.onInfoClick(patch.discuss_url)} />
    ) : null
    
    //const desc = Helpers.linkifyText(patch.desc);
    const desc = 
    (
      <span className='text-pre' dangerouslySetInnerHTML={{__html: Helpers.linkifyText(patch.desc)}} />          
    );

    return (
    <div key={id} className='row file-details-main container'>
      <div className='col-lg-3 col-xs-3 align-self-center'>
        <img className='file-details-banner' src={banner} />
      </div>
      <div className='col-lg-6 col-xs-6'>
        <div className='file-details-name center'>
          {patch.display_name}
        </div>
        {desc}       
        <div className='mt-3'>
          <div>
            {more_info}
            {discuss_url}        
          </div>
        </div>
      </div>
      <div className='col-lg-3 col-xs-3 align-self-center' style={{'marginTop': '10px'}}>
        <div className='center' style={{'marginTop': '15px'}}>
          <CustomButton style={{display: 'inline-block'}} color='success' text='DOWNLOAD' icon={require('../res/download.png')} onClick={() => this.routeToFile(patch, type)} />
        </div>
        <div className='center' style={{'marginTop': '10px'}}>
          <div>
            Latest Version:
          </div>
          <div style={{'fontWeight': '500'}}>
            {last_version}
          </div>
          <div>
            {last_updated}
          </div>
        </div>
      </div>
    </div>
    );
  }

  onInfoClick(linkStr) {
    window.open(linkStr, "_blank");
  }

  routeToFile(patch, type) {
    if (patch) {
      if (type == 0) {
        this.props.history.push({
          pathname: '/downloads/mappacks/' + patch.name,
        })
      } else {
        this.props.history.push({
          pathname: '/downloads/patches/' + patch.name,
        })
      }
    }
  }

  openFileDetails(patch, type) {
    if (patch && patch.data && patch.data.length > 0) {
      this.state.showFilePopup[0] = true;
      this.setState({showFilePopup: this.state.showFilePopup, selectedPatch: patch, selectedPatchType: type, versions: patch.data});
    }
  }

  reportDownload(id) {
    fetch(Helpers.getBaseURL() + '/fetch_files.php', {
      method: 'POST',
      headers: Helpers.addHeaders(),
      body: Helpers.appendParams('type=4&download=' + id)
    })
    .then(response => {
      var stuff = response.json();
      return stuff;
    })
    .then(data => {
      // do nothing
    })
    .catch(function(error) {
     // do nothing
    }.bind(this));
  }

  onFileDetailsClosed() {
    this.props.history.push({
      pathname: '/downloads',
    })
  }

  onFileDownload(name, meta_version_id, metapack_name, isExe) {
    fetch(Helpers.getBaseURL() + '/fetch_files.php', {
      method: 'POST',
      headers: Helpers.addHeaders(),
      body: Helpers.appendParams({
          filter: name,
          meta_version_id: meta_version_id,
          metapack_name: metapack_name
        }
      )
    })
    .then(response => {
      var stuff = response.json();
      return stuff;
    })
    .then(data => {

      if (data.status == '1' && data.output && data.output.length > 0) {
        const popupText = isExe ? "Ready to Download!\n\nThis file contains an installer." : "Ready to Download!"
          + `\n\nThis file is a self-extracting archive for Command Post.`
          + `\nPlease download it via <a href=${Helpers.CP_DOCS} target='_blank'>Command Post</a> to ensure it works correctly.`
        this.state.showPopup[0] = true;
        this.state.selectedUrl = data.output[0].url;
        this.setState({showPopup: this.state.showPopup, popupText: popupText, selectedUrl: this.state.selectedUrl});

        this.reportDownload(data.output[0].id);
      } else {
        this.state.showPopup[0] = true;
        this.state.selectedUrl = null;
        this.setState({showPopup: this.state.showPopup, popupText: data.message, selectedUrl: this.state.selectedUrl});
      }

      this.setState({showLoading: [false]});
      this.forceUpdate();

    })
    .catch(function(error) {
      this.state.err[0] = error
      this.setState({err: this.state.err, showLoading: [false]});
      this.forceUpdate();
    }.bind(this));
  }

  componentWillReceiveProps(props) {
    if (props.match.params && props.match.params.name) {
      const type = props.match.params.type;
      const name = props.match.params.name;
      console.log(`>>> REDIRECT == ${type}, ${name}`)

      const meta = this.props.metadata;
      if (meta) {
        const sets = type == 'mappacks' ? meta.mappack_sets : meta.patch_sets;
        if (sets) {
          for (var i = 0; i < sets.length; i++) {
            const patch = sets[i];
            if (patch.is_active && patch.name == name) {
              this.openFileDetails(patch, type == 'mappacks' ? 0 : 1);
              break;
            }
          }
        }
      }
    }
  }

  onTabChange(newValue) {
    this.setState({
        myTab: newValue
      }
    )
  }

  render() {
    const { metadataErr, metadata, showLoading } = this.props;

    const err = metadataErr

    const myTab = this.state.myTab

    const lists = [];
    const jumpTo = this.state.jumpTo[0];
    var jumpPatch = null;

    if (metadata) {
      const types = ['mappack_sets', 'patch_sets']
      for (var j = 0; j < types.length; j++) {
        const type = types[j]
        lists.push([])
        if (!metadata[type]) continue
        for (var i = 0; i < metadata[type].length; i++) {
          const patch = metadata[type][i];
          if (patch.is_active && patch.is_hosted) {
            lists[j].push(
              this.renderPatch(patch, j)
            );
            if (jumpTo == patch.name) {
              jumpPatch = [patch, j];                
            }
          }
        }
      }
      if (jumpPatch) {
        setTimeout(() => {
          this.state.jumpTo[0] = null;
          this.openFileDetails(jumpPatch[0], jumpPatch[1]);
        }, 10);
      }
    }

    const isLoading = showLoading;

    const selectedPatch = this.state.selectedPatch;
    const selectedPatchType = this.state.selectedPatchType;
    const versions = this.state.versions;

    const helmetContent = (
      <Helmet>
        <title>KW-UPLOADS - Download Patches, Mods, Map Packs</title>
        <meta name="description" content="Download 1.02+ Map Packs, Patch 1.02+, 1.03 and more!" />
        <meta name="keywords" content="Kane's Wrath, KW, C&C3, Command & Conquer 3, Command Post, patches, mods, 1.02+, 1.03" />
      </Helmet>
    );

    const tabContent = (
      <div>
        <TabPanel value='0'>
          <div className='w3-margin files-card container'>                            
          <div className='mb-4 file-type-title'>
            Map Packs contain maps with in-built changes or custom content which do not apply to the rest of the game.
          </div>
            {lists[0]}
          </div>
        </TabPanel>    
        <TabPanel value='1'>
          <div className='w3-margin files-card container'>
            <div className='mb-4 file-type-title'>
              Patches apply changes or custom content globally throughout the game.
            </div>
            {lists[1]}
          </div>
        </TabPanel>        
      </div>
    )

    var content;
    if (!isLoading) {
      if (err) {
        content = (
          <div className='w3-margin err-text-large'>
            {err}
          </div>
        );
      } else {
        content = (
          <div>
            <TabContext value={myTab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <div className='ml-5'>
                    <TabList onChange={(event, newValue) => this.onTabChange(newValue)}>
                      <Tab label="Map Packs" value='0' />
                      <Tab label="Patches" value='1' />
                    </TabList>
                  </div>
              </Box>
              {tabContent}
            </TabContext>

            <Popup url={this.state.selectedUrl} modal={this.state.showPopup} title='Download' text={this.state.popupText} />
            <FileDetailsPopup onFileDownload={this.onFileDownload} modal={this.state.showFilePopup} patch={selectedPatch} type={selectedPatchType} versions={versions} onClosed={this.onFileDetailsClosed} />
          </div>
        )
      };
    } else {
      content = (
        <div className='w3-margin'>
          <Progress bar animated value="100">Loading</Progress>
        </div>
      );
    }

    return (
      <div className="Home">
        {helmetContent}
        <div className='w3-margin'>
          For change logs and other information about a file, click on MORE INFO. Certain downloads may require being registered on <a href={Helpers.CP_DOCS} target='_blank'>Command Post</a>.
        </div>
          {content}
      </div>
    );

  }
}


const mapStateToProps = (state) => {
  return {
    metadata: state.metadata.metadata,
    metadataErr: state.metadata.error,
    showLoading: state.metadata.loading
  }
};

export default withRouter(connect(mapStateToProps)(Downloads));