import {
  SEARCH_UNITS_BEGIN,
  SEARCH_UNITS_SUCCESS,
  SEARCH_UNITS_FAILURE,
  FETCH_TREE_BEGIN,
  FETCH_TREE_SUCCESS,
  FETCH_TREE_FAILURE,
  FETCH_STATS_BEGIN,
  FETCH_STATS_SUCCESS,
  FETCH_STATS_FAILURE,
  SET_ITEM_SELECTION,
  SET_FILE_SELECTION,
  REMOVE_FILE_SELECTION,
} from '../redux-actions/statsdetails.js';

const initialState = {
  stats: [null, null],
  statsLoading: [false, false],
  searchLoading: [false, false],
  error: [null, null],
  statsTypes: [1,1],
  selectedCategories: [1, 1],
  selectedPatches: ['', ''],
  searchLists: [null, null],
  searchValues: ['', ''],
  treeLoading: [false, false],
  trees: {},
  selectedItems: [null, null],
  selectedFiles: [null, null],
  selectedFilesData: [null, null],
  selectedFileValues: ['', ''],
  timestamp: null,
  page: 0,
};

export default function statsReducer(state = initialState, action) {
  const page = action.page
  const statsLoading = state.statsLoading
  const searchLoading = state.searchLoading
  const stats = state.stats
  const statsTypes = state.statsTypes
  const selectedPatches = state.selectedPatches
  const selectedCategories = state.selectedCategories
  const searchLists = state.searchLists
  const searchValues = state.searchValues
  const treeLoading = state.treeLoading
  const trees = state.trees
  const selectedItems = state.selectedItems
  const selectedFiles = state.selectedFiles
  const selectedFilesData = state.selectedFilesData
  const selectedFileValues = state.selectedFileValues
  const error = state.error

  var newPatch, newCategory
  
  switch(action.type) {
    case SEARCH_UNITS_BEGIN:
      searchLoading[page] = action.payload.loading
      searchValues[page] = action.payload.searchValue

      selectedPatches[page] = action.payload.patch
      selectedCategories[page] = Number(action.payload.type)      

      return {
        ...state,
        searchLoading: searchLoading,
        searchValues: searchValues,
        timestamp: new Date().getTime()
      }

    case SEARCH_UNITS_SUCCESS:
      searchLoading[page] = false
      searchLists[page] = action.payload.searchList

      return {
        ...state,
        searchLoading: searchLoading,
        searchLists: searchLists,
        timestamp: new Date().getTime()
      }

    case SEARCH_UNITS_FAILURE:
      searchLoading[page] = false
      searchLists[page] = action.payload.searchList
      
      return {
        ...state,
        searchLoading: searchLoading,        
        searchLists: searchLists,
        timestamp: new Date().getTime()        
      }

    case FETCH_TREE_BEGIN:
      treeLoading[page] = action.payload.loading  

      return {
        ...state,        
        treeLoading,
        timestamp: new Date().getTime()
      }

    case FETCH_TREE_SUCCESS:
      trees[action.payload.patch] = action.payload.tree
      treeLoading[page] = false

      return {
        ...state,
        trees,
        treeLoading,
        timestamp: new Date().getTime()
      }

    case FETCH_TREE_FAILURE:
      treeLoading[page] = false
      
      return {
        ...state,
        treeLoading,
        timestamp: new Date().getTime()
      }

    case SET_ITEM_SELECTION:
      selectedItems[page] = action.payload.item

      return {
        ...state,
        selectedItems,
        timestamp: new Date().getTime()        
      }

    case SET_FILE_SELECTION:
      selectedFiles[page] = action.payload.file
      selectedFilesData[page] = action.payload.fileData

      return {
        ...state,
        selectedFiles,
        selectedFilesData,
        timestamp: new Date().getTime()
      }

    case REMOVE_FILE_SELECTION:
      selectedFiles[page] = null
      selectedFilesData[page] = null
      selectedFileValues[page] = ''
      stats[page].icon = null

      return {
        ...state,
        selectedFiles,
        selectedFilesData,
        selectedFileValues,
        timestamp: new Date().getTime()
      }

    case FETCH_STATS_BEGIN:
      newPatch = action.payload.patch
      newCategory = Number(action.payload.type)

      statsLoading[page] = action.payload.loading
      error[page] = null

      if (selectedPatches[page] != newPatch || selectedCategories[page] != newCategory) {
        trees[newPatch] = undefined
      }

      selectedPatches[page] = action.payload.patch
      selectedCategories[page] = Number(action.payload.type)

      return {
        ...state,
        statsLoading: statsLoading,
        error: error,
        selectedPatches: selectedPatches,
        selectedCategories: selectedCategories,
        trees,
        timestamp: new Date().getTime()
      };

    case FETCH_STATS_SUCCESS: 
      newPatch = action.payload.patch
      newCategory = Number(action.payload.type)

      statsLoading[page] = false
      stats[page] = action.payload.stats
      statsTypes[page] = Number(action.payload.type)

      if (selectedPatches[page] != newPatch || selectedCategories[page] != newCategory) {
        if (trees[newPatch])
          trees[newPatch] = undefined
      }

      selectedPatches[page] = newPatch
      selectedCategories[page] = newCategory

      selectedFiles[page] = null
      selectedFilesData[page] = null
      selectedFileValues[page] = ''

      return {
        ...state,
        statsLoading: statsLoading,
        stats: stats,
        statsTypes: statsTypes,
        selectedPatches: selectedPatches,
        selectedCategories, selectedCategories,
        trees,
        selectedFiles,
        selectedFilesData,
        selectedFileValues,
        timestamp: new Date().getTime(),
      };

    case FETCH_STATS_FAILURE:
      statsLoading[page] = false
      error[page] = action.payload.error
      stats[page] = action.payload.stats

      return {
        ...state,
        statsLoading: statsLoading,
        error: error,
        stats: stats,
        statsTypes: statsTypes,
        timestamp: new Date().getTime()
      };

    default:
      return state;
  }
}