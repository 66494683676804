import Helpers from '../Helpers.js';

export const FETCH_DONATIONS_BEGIN   = 'FETCH_DONATIONS_BEGIN';
export const FETCH_DONATIONS_SUCCESS = 'FETCH_DONATIONS_SUCCESS';
export const FETCH_DONATIONS_FAILURE = 'FETCH_DONATIONS_FAILURE';

export const fetchDonationsBegin = () => ({
  type: FETCH_DONATIONS_BEGIN
});

export const fetchDonationsSuccess = donations => ({
  type: FETCH_DONATIONS_SUCCESS,
  payload: { donations }
});

export const fetchDonationsFailure = error => ({
  type: FETCH_DONATIONS_FAILURE,
  payload: { error }
});

export function reportDonations() {
    return dispatch => {
        dispatch(fetchDonationsBegin());
        return fetch(Helpers.getBaseURL() + '/fetch_donations.php', {
            method: 'POST',
            headers: Helpers.addHeaders(),
            body: Helpers.appendParams('type=4')
        })
            .then(res => res.json())
            .then(data => {
                if (data.status == "1" && data.output) {
                    dispatch(fetchDonationsSuccess(data.output));
                    return data.output;
                } else {
                    dispatch(fetchDonationsFailure(data.message));
                }
            })
            .catch(error => dispatch(fetchDonationsFailure(error)));
    };
}

export function fetchDonations() {
  return dispatch => {
    dispatch(fetchDonationsBegin());
    return fetch(Helpers.getBaseURL() + '/fetch_donations.php', {
      method: 'POST',
      headers: Helpers.addHeaders(),
      body: Helpers.appendParams('type=0')
    })
      .then(res => res.json())
      .then(data => {
        if (data.status == "1" && data.output) {
          dispatch(fetchDonationsSuccess(data.output));
          return data.output;
        } else {
          dispatch(fetchDonationsFailure(data.message));
        }
      })
      .catch(error => dispatch(fetchDonationsFailure(error)));
  };
}
