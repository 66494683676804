import Helpers from '../Helpers.js';

export const SEARCH_UNITS_BEGIN = 'SEARCH_UNITS_BEGIN';
export const SEARCH_UNITS_SUCCESS = 'SEARCH_UNITS_SUCCESS';
export const SEARCH_UNITS_FAILURE = 'SEARCH_UNITS_FAILURE';
export const FETCH_TREE_BEGIN = 'FETCH_TREE_BEGIN';
export const FETCH_TREE_SUCCESS = 'FETCH_TREE_SUCCESS';
export const FETCH_TREE_FAILURE = 'FETCH_TREE_FAILURE';
export const SET_ITEM_SELECTION = 'SET_ITEM_SELECTION';
export const FETCH_STATS_BEGIN   = 'FETCH_STATS_BEGIN';
export const FETCH_STATS_SUCCESS = 'FETCH_STATS_SUCCESS';
export const FETCH_STATS_FAILURE = 'FETCH_STATS_FAILURE';
export const SET_FILE_SELECTION = 'SET_FILE_SELECTION';
export const REMOVE_FILE_SELECTION = 'REMOVE_FILE_SELECTION';

export const searchUnitsBegin = (page, searchValue, patch, type, loading) => ({
  type: SEARCH_UNITS_BEGIN,
  page: page,
  payload: {
    searchValue, patch, type, loading
  }
});

export const searchUnitsSuccess = (page, searchList) => ({
  type: SEARCH_UNITS_SUCCESS,
  page: page,
  payload: {
    searchList    
  }

});

export const searchUnitsFailure = (page, error) => ({
  type: SEARCH_UNITS_FAILURE,
  page: page,
  payload: {
    error,
    searchList: null
  }
});

export const fetchTreeBegin = (page, patch, type, loading) => ({
  type: FETCH_TREE_BEGIN,
  page: page,
  payload: {
    patch, type, loading
  }
});

export const fetchTreeSuccess = (page, patch, tree) => ({
  type: FETCH_TREE_SUCCESS,
  page: page,
  payload: {
    patch, tree    
  }

});

export const fetchTreeFailure = (page, error) => ({
  type: FETCH_TREE_FAILURE,
  page: page,
  payload: {
    error
  }
});

export const setItemSelectionSuccess = (page, item) => ({
  type: SET_ITEM_SELECTION,
  page: page,
  payload: {
    item
  }
})

export const fetchStatsBegin = (page, patch, type, loading) => ({
  type: FETCH_STATS_BEGIN,
  page: page,
  payload: {
    patch, type, loading
  }
});

export const fetchStatsSuccess = (page, stats, type, patch) => ({
  type: FETCH_STATS_SUCCESS,
  page: page,
  payload: {
    stats, patch, type
  }
});

export const fetchStatsFailure = (page, error) => ({
  type: FETCH_STATS_FAILURE,
  page: page,
  payload: {
    error,
    stats: null
  }
});

export function modifyStats(stats, page, type, patch) {
  return dispatch => {
    dispatch(fetchStatsSuccess(page, stats, type, patch))
  }
}

export function setItemSelection (page, item) {
  return dispatch => {
    dispatch(setItemSelectionSuccess(page, item))
  }
}

export const setFileSelection = (page, file, fileData) => ({
  type: SET_FILE_SELECTION,
  page: page,
  payload: {
    file,
    fileData
  }
});

export const removeFileSelection = (page) => ({
  type: REMOVE_FILE_SELECTION,
  page: page
})

export function setFileAttachment(page, file, fileData) {
  return dispatch => {
    dispatch(setFileSelection(page, file, fileData))
  }
}

export function removeFileAttachment(page) {
  return dispatch => {
    dispatch(removeFileSelection(page))
  }
}

export function postData(page, type, data, iconFile, showPopup, onSuccess, onFailure) {
  return dispatch => {
    const formData = new FormData()
    formData.append('name', data.id)
    formData.append('patch', data.patch)
    formData.append('desc', data.summary)
    if (iconFile)
        formData.append('file', iconFile)

    const route = getRoute(type)
    const action = 'edit'

    formData.append('route', route)
    formData.append('action', action)

    fetch(Helpers.getIntelDbURL(route, action), {
        method: 'POST',
        body: Helpers.appendParams(formData)
    })
    .then(response => response.json())
    .then(res => {        
        var output = []
        if (res.status == '1' && res.output && res.output.length > 0) {
            output = res.output
  
            dispatch(fetchStatsSuccess(page, output[0], type, output[0].patch))
  
            // Reset portrait
            dispatch(removeFileAttachment(page))
  
            if (onSuccess)
                onSuccess()
        } else {
            if (onFailure)
                onFailure(res.message)
        }
  
        if (showPopup)
          showPopup(res.message)
    })
    .catch(function(error) {  
        if (onFailure)
            onFailure(error.message)          
    });    
  }    
}

export function searchUnits(page, searchValue, patch, type, isLoading) {
  return dispatch => {

    if (!patch || !type || isLoading || !(searchValue && searchValue.length > 1)) {
      dispatch(searchUnitsBegin(page, searchValue, patch, type, false))
      return
    }

    const route = getRoute(type)
    const action = 'search'

    const query =  new URLSearchParams({
      patch: patch,
      text: searchValue,
      route: route,
      action: action
    }).toString()

    dispatch(searchUnitsBegin(page, searchValue, patch, type, true))

    fetch(Helpers.getIntelDbURL(route, action, query), {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    })
    .then(response => response.json())
    .then(data => {

      var output = []
      if (data.status == '1' && data.output && data.output.length > 0) {
        output = data.output      
        dispatch(searchUnitsSuccess(page, output))
      } else {
        dispatch(searchUnitsFailure(page, data.message))        
      }
    })
    .catch(error => dispatch(searchUnitsFailure(page, error.message)));      
  }
}

export function fetchTree(page, patch, type, isLoading) {
  return dispatch => {

    if (!patch || isLoading) {
      dispatch(fetchTreeBegin(page, patch, type, false))
      return
    }

    const route = getRoute(type)
    const action = 'tree'

    const query = new URLSearchParams({
      patch: patch,
      route: route,
      action: action
    }).toString()

    dispatch(fetchTreeBegin(page, patch, type, true))

    fetch(Helpers.getIntelDbURL(route, action, query), {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    })
    .then(response => response.json())
    .then(data => {

      if (data.status == '1' && data.output && data.output.length > 0) {
        dispatch(fetchTreeSuccess(page, patch, data.output))
      } else {
        dispatch(fetchTreeFailure(page, data.message))        
      }
    })
    .catch(error => dispatch(fetchTreeFailure(page, error.message)));      
  }
}

export function fetchStatsByName(page, name, patch, type, upgrades = null, powers = null) {
  return dispatch => {

    if (!name || !patch || !type) {
      dispatch(fetchStatsBegin(page, patch, type, false));
      return
    }

    const route = getRoute(type)
    const action = ''

    const query = new URLSearchParams({
      name: name,
      patch: patch,
      upgrades: upgrades,
      powers: powers,
      route: route,
      action: action,
    }).toString()

    dispatch(fetchStatsBegin(page, patch, type, true));

    return fetch(Helpers.getIntelDbURL(route, action, query), {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.status == "1" && data.output && data.output.length > 0) {
        dispatch(fetchStatsSuccess(page, data.output[0], type, data.output[0].patch));
      } else {
        dispatch(fetchStatsFailure(page, data.message));
      }
    })
    .catch(error => dispatch(fetchStatsFailure(page, error.message)));
  };
}

function getRoute(type) {
  switch(type) {
    default:
      return 'gameobjects'
  }
}