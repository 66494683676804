import { combineReducers } from 'redux';
import misc from './misc';
import convos from './convos';
import users from './users';
import tournaments from './tournaments';
import donations from './donations';
import transactions from './transactions';
import metadata from './metadata';
import statsdetails from './statsdetails';
export default combineReducers({
  misc,
  convos,
  users,
  tournaments,
  donations,
  transactions,
  metadata,
  statsdetails
});