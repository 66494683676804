import {
    FETCH_USERS_BEGIN,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAILURE
  } from '../redux-actions/users.js';
  
  const initialState = {
    items: [],
    loading: false,
    error: null,
    timestamp: 0
  };
  
  export default function messageReducer(state = initialState, action) {
    switch(action.type) {
      case FETCH_USERS_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
  
      case FETCH_USERS_SUCCESS:
        return {
          ...state,
          loading: false,
          items: action.payload.users,
          timestamp: new Date().getTime()
        };
  
      case FETCH_USERS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          items: [],
          timestamp: new Date().getTime()
        };
  
      default:
        return state;
    }
  }