import React, { Component, Fragment } from 'react';
import './StatsSheet.css';
import Helpers from '../Helpers.js';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import StatsDetails from '../components/StatsDetails';
import Popup from '../components/Popup';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditStatsDetailsPopup from '../components/EditStatsDetailsPopup';
import UnitTreePopup from '../components/UnitTreePopup';
import { postData, fetchStatsByName, fetchTree} from '../redux-actions/statsdetails';

class StatsSheet extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showLoading: [false],
      err: [''],
      editContent: null,
      showEditModal: [false],
      onEditCallback: null,
      popupMsg: [''],
      showPopup: [false],
      showTree: [false, false],
      onTreeCallback: null,
      patchForTree: null
    };

    this.onEditClick = this.onEditClick.bind(this)
    this.onSaveClick = this.onSaveClick.bind(this)
    this.togglePopup = this.togglePopup.bind(this)
    this.onTreeOpen = this.onTreeOpen.bind(this)
    this.onTreeItemSelected = this.onTreeItemSelected.bind(this)
  }

  onSaveClick(page, type, data, iconFile) {
    if (data) {
      this.props.dispatch(postData(page, type, data, iconFile, this.togglePopup))
    }
  }

  onEditClick(data, onEditSuccess) {
    if (data) {
      this.state.showEditModal[0] = true
      this.setState({
        showEditModal: this.state.showEditModal, 
        editContent: data.summary, 
        onEditCallback: (newContent) => onEditSuccess(data, newContent)
      })
    }
  }

  onTreeOpen(page, patch, type, pushQueryParamsCallback) {
    if (!this.props.trees[patch])
      this.props.dispatch(fetchTree(page, patch, type))

    this.state.showTree[0] = true
    this.setState({showTree: this.state.showTree, patchForTree: patch, onTreeCallback: (id) => this.onTreeItemSelected(page, id, patch, type, pushQueryParamsCallback) })
  }

  onTreeItemSelected(page, id, patch, type, pushQueryParamsCallback) {    
    this.props.dispatch(fetchStatsByName(page, id, patch, type))

    if (pushQueryParamsCallback)
      pushQueryParamsCallback(id, '', patch, type)

    this.state.showTree[0] = false
    this.setState({showTree: this.state.showTree})
  }

  togglePopup(msg) {
    this.state.showPopup[0] = true
    this.state.popupMsg[0] = msg
    this.setState({popupMsg: this.state.popupMsg, showPopup: this.state.showPopup})
  }

  render() {
    const { trees, isMetadataLoading } = this.props

    const class1 = 'col-lg-6 col-md-6 col-sm-12'
    const class2 = 'col-lg-6 col-md-6 col-sm-12'

    const loadingBar = isMetadataLoading ? (
        <div className='stats-sheet-loading-div'>
          <div className='stats-sheet-loading-text'>Initializing... Please Wait</div>
          <CircularProgress color="inherit" size={50} />        
        </div>
    ) : null

    const showStatBlock = isMetadataLoading ? 'none' : 'block'

    return (
      <div>
        <div className='Users w3-margin'>
          <div style={{textAlign: 'center', marginBottom: '15px', fontSize: '28px'}}>IntelDB<sup style={{color: 'red'}}>BETA</sup></div>
          <div>
            IntelDB allows you to look up and compare stats for units, upgrades and abilities - on ANY patch! To upload stats for your own patch and lots of other cool stuff, download <a href={Helpers.CP_URL}>Command Post</a>.
          </div>
            <div className='row' style={{marginTop: '15px'}}>
              <div className={class1} style={{display: showStatBlock}}>
                <StatsDetails page='0' onEditClick={this.onEditClick} onSaveClick={this.onSaveClick} onTreeOpen={this.onTreeOpen} showPopup={this.togglePopup} />
              </div>
              <div className={class2} style={{display: showStatBlock}}>
                <StatsDetails page='1' onEditClick={this.onEditClick} onSaveClick={this.onSaveClick} onTreeOpen={this.onTreeOpen} showPopup={this.togglePopup} />
              </div>               
              {loadingBar}
          </div>
        </div>
        <EditStatsDetailsPopup data={this.state.editContent} modal={this.state.showEditModal} onSubmit={this.state.onEditCallback} />
        <UnitTreePopup data={trees} modal={this.state.showTree} loading={this.state.treeLoading} patch={this.state.patchForTree} onItemSelected={this.state.onTreeCallback} />
        <Popup modal={this.state.showPopup} title='Info' text={this.state.popupMsg[0]} />
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    metadata: state.metadata.metadata,
    isMetadataLoading: state.metadata.loading,
    stats: state.statsdetails.stats,
    statsTypes: state.statsdetails.statsTypes,      
    detailsLoading: state.statsdetails.loading,    
    trees: state.statsdetails.trees,
    treeLoading: state.statsdetails.treeLoading,
    timestamp: state.statsdetails.timestamp
  }
};

export default connect(mapStateToProps)(StatsSheet);
