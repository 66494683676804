import React, { Component } from 'react';
import './ThankYouPage.css'
import { connect } from "react-redux";
import { reportDonations } from "../redux-actions/donations.js";
import Helpers from '../Helpers.js';

class ThankYouPage extends Component {

  componentDidMount () {
  }

  render() {
    this.props.dispatch(reportDonations());

    const txt = "Thanks for donating! You will receive a payment details receipt by email.\n\nDonations will be used towards future events and improved services for the Kane\'s Wrath community!\n\n Note: The donations amount shown above may take a few minutes to update.";

    return (
      <div className='thankyou-div'>
        Donation Successful!
        <br />
        <div className='thankyou-content-div'>
          <img className='thankyou-img' src={require('../res/kane-tib.jpg')} />
          <div className='thankyou-txt text-pre'>
            {txt}
          </div>
        </div>
       </div>
    );
  }
}


//export default ThankYouPage;


const mapStateToProps = state => ({
});

export default connect(mapStateToProps)(ThankYouPage);