import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import combineReducers from './redux-reducers/index.js';

import { config } from "./firebaseConfig";
import { initializeApp } from "firebase/app";

initializeApp(config);

const store = createStore(combineReducers, applyMiddleware(thunk));

ReactDOM.render(
<App store={store} /> ,
 document.getElementById('root'));
registerServiceWorker();
//unregister();

