import Helpers from '../Helpers.js';

export const FETCH_TOURNAMENTS_BEGIN   = 'FETCH_TOURNAMENTS_BEGIN';
export const FETCH_TOURNAMENTS_SUCCESS = 'FETCH_TOURNAMENTS_SUCCESS';
export const FETCH_TOURNAMENTS_FAILURE = 'FETCH_TOURNAMENTS_FAILURE';

export const fetchTournamentsBegin = () => ({
  type: FETCH_TOURNAMENTS_BEGIN
});

export const fetchTournamentsSuccess = tournament => ({
  type: FETCH_TOURNAMENTS_SUCCESS,
  payload: { tournament }
});

export const fetchTournamentsFailure = error => ({
  type: FETCH_TOURNAMENTS_FAILURE,
  payload: { error }
});

export function fetchTournaments(tournament_id) {
  return dispatch => {
    dispatch(fetchTournamentsBegin());
    return fetch(Helpers.getBaseURL() + '/play_tournament.php', {
      method: 'POST',
      headers: Helpers.addHeaders(),
      body: Helpers.appendParams('type=0' + 'tournament_id=' + tournament_id)
    })
      .then(res => res.json())
      .then(data => {
        dispatch(fetchTournamentsSuccess(data.output));
        return data.output;
      })
      .catch(error => dispatch(fetchTournamentsFailure(error)));
  };
}
