import React from 'react';
import './PrivacyPolicy.css';

class PrivacyPolicy extends React.Component {
    constructor(props) {
      super(props);
    }

    render() {
        return (
            <div className='PrivacyPolicy w3-margin'>
                <div style={{'textAlign': 'center'}}>
                    <h2>Privacy Policy</h2>
                </div>
                <div>
                    <p>
                        <h4>Website</h4>
                        KW-UPLOADS may use cookies and other parameters in your web browser to distinguish you from other users. This helps us to provide you with a good experience when you browse this website and also allows us to improve them. By continuing to browse this website, you are agreeing to our use of this data. 
                    </p>
                    <p>
                        <h4>Command Post</h4>
                        Command Post uses data stored in your 'Game Folders' to provide you with a convenient interface to customize and interact with your game. 'Game Folders' refers to your game installation directory, registry keys and associated folders in Documents/AppData which may contain your game profile data, replays, patches, maps and other game addons. Note that such information by itself cannot be used to personally identify; it is solely used to provide meaningful functionality to users. Personal information (Email) may be collected voluntarily, and certain device details (such as OS, MAC, IP Address) may be collected involuntarily; this information is used to distinguish you from other users, assist in password/account recovery, and for error logging purposes. Such sensitive information is purely used to improve the quality of our services, provide maximum benefit to your person, and is strictly confidential (not exposed to members of the public or third parties). Information exposed publicly by certain features such as Replays and Match History may include your IP address; however, this is not voluntarily gathered and exposed by our software but merely a reflection of the data collected by (and obtainable from) the game software. By installing Command Post on your system, you are agreeing to share the aforementioned information with the platform. 
                    </p>
                </div>
            </div>
        );     
    }

}

export default PrivacyPolicy;