import {
  FETCH_METADATA_BEGIN,
  FETCH_METADATA_SUCCESS,
  FETCH_METADATA_FAILURE,
} from '../redux-actions/metadata.js';

const initialState = {
  metadata: [],
  loading: false,
  error: null,
  timestamp: 0,
  isLoaded: false
};

export default function metadataReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_METADATA_BEGIN:

      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_METADATA_SUCCESS:
      return {
        ...state,
        loading: false,
        metadata: action.payload.metadata,
        timestamp: new Date().getTime(),
        isLoaded: true
      };

    case FETCH_METADATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        metadata: [],
        timestamp: new Date().getTime()
      };

    default:
      return state;
  }
}