import React, { Component } from 'react';
import './UserDetails.css';
import Helpers from '../Helpers.js';


class UserDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      onUserClick: this.props.onUserClick,
      type: this.props.type,
      refTime: this.props.refTime,
    };
  }

  componentWillReceiveProps(props) {
    this.state.user = this.props.user;
    this.state.refTime = this.props.refTime;
    this.setState({user: this.state.user, refTime: this.state.refTime});
    this.forceUpdate();
  }

  render() {
      var userType = 'n/a';
      switch (this.state.user.status) {
        case '0':
          userType = 'Regular User';
          break;
        case '1':
          userType = 'Super User';
          break;
        case '5':
          userType = 'Banned';
          break;
        case '100':
          userType = 'Super Admin';
          break;
      }
      const userType1 = userType;

      var lastActive = 'n/a';
      var lastActiveStyle = {'color': 'black'};
      const lastActive1 = Helpers.activityTimeToStr(this.state.user.last_activity, this.state.refTime[0]);
      if (lastActive1.seconds && lastActive1.seconds < 75) {
        lastActiveStyle = {'color': 'green'};
      }
      const lastActiveStyle1 = lastActiveStyle;

      const activityObj = Helpers.activityTimeToStr(this.state.user.last_login, this.state.refTime[0]);
      const lastLogin1 = activityObj.seconds;

      var userStatus = 'Offline';
      var userStatusStyle = {'color': 'black'};
      if (activityObj['is_valid'] == 1 && lastLogin1 < 75) {
        switch (this.state.user.game_status) {
          case '0':
            userStatus = 'Online';
            userStatusStyle = {'color': 'green'};
            break;
          case '1':
            userStatus = 'Online/In-Game Alt-Tabbed';
            userStatusStyle = {'color': 'darkgreen'};
            break;
          case '2':
            userStatus = 'Online/In-Game';
            userStatusStyle = {'color': 'darkgreen'};
            break;
        }
      }

      const userStatus1 = userStatus;
      const userStatusStyle1 = userStatusStyle;

      const avatar = Helpers.loadAvatar(this.state.user.avatar);

      return (
        <div className='UserDetails'>
          <div className='row'>
            <div className='col-lg-4 col-xs-6 user-details-user-div' onClick={() => this.state.onUserClick(this.state.user)}>
              <div className=''>
                <div className='row'>
                  <div className='col-2'>
                    <img className='avatar-med' src={avatar} style={{'margin': '5px'}} />
                  </div>
                  <div className='col-10'>
                    <div>
                      <div className='user-details-username-div'>
                        {this.state.user.name}
                      </div>
                      <div className='user-details-usertype-div'>
                        {userType1}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-xs-6 user-details-misc-div'>
              <div className=''>
                <div className='row'>
                  <div className='col-6'>
                    <div className='user-details-useractivity-div'>
                      Status
                    </div>
                    <div style={userStatusStyle1}>
                      {userStatus1}
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='user-details-useractivity-div'>
                      Last Active
                    </div>
                    <div style={lastActiveStyle1}>
                      {lastActive1.str}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }

}


export default UserDetails;
