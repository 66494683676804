import React, { Component } from 'react';
import Helpers from '../Helpers.js';
import moment from 'moment';
import './TournamentDetails.css';
import { Nav, NavItem, Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { Progress } from 'reactstrap';
import Popup from '../components/Popup.js';

class TournamentDetails extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            tournament_id : props.match.params.id,
            tournament: null,
            curTab: [props.match.params.tab],
            infoHtml: null,
            showLoading: [false],
            err: '',
            showPopup: [false],
            popupMsg: [null],
            cdTimer: [null],
            countdownTxt: ['0D 0H 0m 0s'],
            countdownColor: null,
        };

        this.fetchTournamentDetails = this.fetchTournamentDetails.bind(this);
        this.fetchHtml = this.fetchHtml.bind(this);
        this.getTeamById = this.getTeamById.bind(this);
        this.onSignUpClick = this.onSignUpClick.bind(this);
        this.onCheckInClick = this.onCheckInClick.bind(this)
        this.updateCountdown = this.updateCountdown.bind(this)
        this.checkCheckInStatus = this.isCheckInAllowed.bind(this)
        this.checkSignUpStatus = this.isSignUpAllowed.bind(this)
        this.doShowPopUp = this.doShowPopUp.bind(this)
    }

    componentWillReceiveProps(props) {
        this.state.curTab[0] = props.match.params.tab;
    }

    componentDidMount() {
        this.state.cdTimer[0] = setInterval(() => {
            this.updateCountdown()
          }, 1000)
        this.fetchTournamentDetails();
    }

    componentWillUnmount() {
        if (this.state.cdTimer[0]) {
            clearInterval(this.state.cdTimer[0])
            this.state.cdTimer[0] = null
        }
    }

    updateCountdown() {
        const tournament = this.state.tournament
        if (tournament && tournament.date_event) {
            const today = moment.utc()
            var eventDate = moment.utc(tournament.date_event, "YYYY-MM-DD HH:mm:ss")
            if (!eventDate.isAfter(today)) {
                eventDate = today
            }
            const ts_event = moment.duration(eventDate.diff(today));
            var countdownColor = 'lightgoldenrodyellow'
            if (ts_event.asDays() <= 1 && tournament.status <= 1) {
                countdownColor = 'lightgreen'
            }
            
            this.state.countdownTxt[0] = ts_event.days() + "D " 
            + ts_event.hours() + "H "
            + ts_event.minutes() + "m " 
            + ts_event.seconds() + "s"         
            this.setState({countdownTxt: this.state.countdownTxt, countdownColor: countdownColor})
        }        
    }

    fetchHtml(infoUrl) {                            
        fetch(Helpers.getBaseURL() + '/fetch_html.php', {
            method: 'POST',
            headers: Helpers.addHeaders(),
            body: Helpers.appendParams('url=' + infoUrl)
          })
          .then(response => {
            var stuff = response.text();
            return stuff;
          })
          .then(data => {
            var parser = new DOMParser();
            var doc = parser.parseFromString(data, "text/html");
            var docHead = doc.getElementsByTagName('head')[0];
            if (docHead) {
                docHead.remove();
            }

            this.setState({infoHtml: doc.documentElement.innerHTML, err: ''});
            this.forceUpdate();
          })
          .catch(function(error) {
            console.log(error);
          }.bind(this));
    }

    fetchTournamentDetails() {
        this.state.showLoading[0] = true;
        this.setState({'showLoading': this.state.showLoading});

        fetch(Helpers.getBaseURL() + '/play_tournament.php', {
            method: 'POST',
            headers: Helpers.addHeaders(),
            body: Helpers.appendParams('tournament_id=' + this.state.tournament_id)
          })
          .then(response => {
            var stuff = response.json();
            return stuff;
          })
          .then(data => {

            this.state.showLoading[0] = false;

            if (data.status == '1' && data.output) {
                this.fetchHtml(data.output.url);
                this.setState({tournament: data.output, err: '', showLoading: this.state.showLoading});
                this.updateCountdown()
            } else {
                this.setState({tournament: null, err: data.message, showLoading: this.state.showLoading});
            }            
          })
          .catch(function(error) {
            this.state.showLoading[0] = false;
            this.setState({err: error.message, showLoading: this.state.showLoading});
          }.bind(this));
    }

    getTeamById(teams, id) {
        for (var i = 0; i < teams.length; i++) {
            if (teams[i].id == id)
                return teams[i];
        }
        return null;
    }

    doShowPopUp(msg) {
        this.state.popupMsg = msg;
        this.state.showPopup[0] = true;
        this.setState({popupMsg: this.state.popupMsg, showPopup: this.state.showPopup});        
    }

    onCheckInClick() {
        this.doShowPopUp( `To Check In, please use Command Post. <a href='commandpost://tournaments?id=${this.state.tournament_id}'>Open in Command Post</a>`)
    }

    onSignUpClick(isSignOut) {
        fetch(Helpers.getBaseURL() + '/play_tournament.php', {
            method: 'POST',
            headers: Helpers.addHeaders(),
            body: Helpers.appendParams({tournament_id: this.state.tournament_id, type: isSignOut ? 2 : 1})
          })
          .then(response => {
            var stuff = response.json();
            return stuff;
          })
          .then(data => {
            if (data.status == '1' && data.output) {
                this.setState({tournament: data.output, err: ''});
                if (data.message && data.message.length > 0) {
                    this.doShowPopUp(data.message)
                }
            } else {
                this.doShowPopUp(data.message)
            }            
          })
          .catch(function(error) {
            this.setState({err: error.message});
          }.bind(this));        
    }

    isSignUpAllowed(tournament, creationDate, eventDate, today, myStatus) {
        const dt_now = today
        const dt_signup_start = tournament.time_limits[0] == '0' ? creationDate : eventDate.clone().subtract(parseInt(tournament.time_limits[0]), 'seconds')
        const dt_signup_end = tournament.time_limits[1] == '0' ? null : eventDate.clone().subtract(parseInt(tournament.time_limits[1]), 'seconds')
        if (dt_now.isAfter(dt_signup_start) && (!dt_signup_end || dt_now.isBefore(dt_signup_end))) {
            return myStatus > -1 ? 1 : 0
        }
        return -1
    }

    isCheckInAllowed(tournament, creationDate, eventDate, today, myStatus) {
        if (myStatus == 1) return false
        const dt_now = today
        const dt_checkin_start = tournament.time_limits[2] == '0' ? creationDate : eventDate.clone().subtract(parseInt(tournament.time_limits[2]), 'seconds')
        const dt_checkin_end = tournament.time_limits[3] == '0' ? null : eventDate.clone().subtract(parseInt(tournament.time_limits[3]), 'seconds')
        if (dt_now.isAfter(dt_checkin_start) && (!dt_checkin_end || dt_now.isBefore(dt_checkin_end))) {
            return true
        }
        return false
    }

    render() {
        var content;
        const tournament = this.state.tournament
        const tournament_id = tournament == null ? null : tournament.id;

        if (this.state.showLoading[0]) {
            content = (
                <Progress bar animated value="100">Loading</Progress>
            );
        } else {
            if (this.state.err) {
                content = (<div className='err-text-large'>{this.state.err}</div>);
            } else if (tournament) {
                var subContent = null;
                const curTab = this.state.curTab[0];

                if (curTab && curTab.startsWith('players')) { // PLAYERS
                    const players = [];
                    for (var i = 0; i < tournament.players.length; i++) {
                        const player = tournament.players[i];
                        var playerStatus = 'Signed Up';
                        var playerColor = 'orange';
                        if (player.status == 1) {
                            playerStatus = 'Checked In';
                            playerColor = 'lightgreen';
                        } else if (player.status == 2) {
                            playerStatus = 'Inactive';
                            playerColor = 'lightgray';
                        } else if (player.status == 3) {
                            playerStatus = 'DQ';
                            playerColor = 'red';
                        }
                        const key = 'player-' + i;

                        var team = "-"
                        if (player.team > 0) {
                            team = player.team
                        }
                        var group = '-'
                        if (player.group > 0) {
                            group = player.group
                        }

                        const playerIcon = player.is_admin > 0 ? (
                            <img src={require('../res/admin.png')} className='icon-player' />
                         ) : null

                        players.push(
                            <div key={key} className='row' style={{color: playerColor}}>
                                <div className='col-1'>{i+1}</div>                             
                                <div className='col-3'>{playerIcon}{player.name}</div>
                                <div className='col-2'>{playerStatus}</div>
                                <div className='col-2'>{player.rating}</div>
                                <div className='col-2'>{team}</div>
                                <div className='col-2'>{group}</div>
                            </div>
                        );
                    }

                    subContent = (
                        <div className='w3-margin div-players-list'>
                            <div className='row div-players-header'>
                                <div className='wt-500 col-1'>
                                    #
                                </div>
                                <div className='wt-500 col-3'>
                                    Player
                                </div>
                                <div className='wt-500 col-2'>
                                    Status
                                </div>
                                <div className='wt-500 col-2'>
                                    Rating
                                </div>
                                <div className='wt-500 col-2'>
                                    Team
                                </div>
                                <div className='wt-500 col-2'>
                                    Group
                                </div>
                            </div>
                            <div className='div-players-body'>
                                {players}
                            </div>
                        </div>
                    );
                } else if (curTab && curTab.startsWith('brackets')) { // BRACKETS

                    if (!tournament.brackets || !tournament.brackets.rounds) {
                        subContent = (
                            <div className='w3-margin'>
                                <div className='err-text-large'>
                                    Brackets have not been generated for this Tournament! Please check back later.  
                                </div>
                            </div>
                        );    
                    } else {
                        const brackets = tournament.brackets;
                        const numRounds = brackets.rounds.length;

                        var maxHeight = 200;
                        var maxWidth = 200;
                        
                        // Title row
                        const titleRow = [];
                        for (var i = 0; i < numRounds - 1; i++) {
                            const rnd = brackets.rounds[i];
                            var rndName = 'Round ' + (i+1);
                            var rndBo = '';
                            var rndMaps = '';
                            if (i == numRounds - 2) {
                                rndName = 'Finals';
                                if (brackets.round_formats) {
                                    rndBo = 'BO' + rnd.bo;
                                    rndMaps = brackets.round_formats[3].desc;
                                }
                            } else if (i == numRounds - 3) {
                                rndName = 'Semi-Finals';
                                if (brackets.round_formats) {
                                    rndBo = 'BO' + rnd.bo;
                                    rndMaps = brackets.round_formats[2].desc;
                                }
                            } else if (i == numRounds - 4) {
                                rndName = 'Quarter-Finals';
                                if (brackets.round_formats) {
                                    rndBo = 'BO' + rnd.bo;
                                    rndMaps = brackets.round_formats[1].desc;
                                }
                            } else {
                                rndName = 'Round ' + (i+1);
                                if (brackets.round_formats) {
                                    rndBo = 'BO' + rnd.bo;
                                    rndMaps = brackets.round_formats[0].desc;
                                }
                            }
                            const x = rnd.sets[0].coords.pos[0].x - 15;
                            const titleStyle = {position: 'absolute', left: x + 'px'};
                            const rndKey = 'rndTitle-' + i;
                            titleRow.push(
                                <div key={rndKey} className='div-round-title' style={titleStyle}>
                                    <div className='div-round-title-name'>
                                        {rndName}
                                    </div>
                                    <div className='div-round-title-desc-container'>
                                        <div className='div-round-title-bo'>
                                            {rndBo}
                                        </div>
                                        <div className='div-round-title-maps'>
                                            {rndMaps}
                                        </div>
                                    </div>
                                </div>
                            );                                                            
                        }

                        

                        // Content rows
                        const contentRows = [];
                        const lines = [];
                        var lineId = 0;
                        for (var i = 0; i < numRounds; i++) {
                            const rnd = brackets.rounds[i];
                            if (rnd.sets) {
                                var c = -1;
                                for (var j = 0; j < rnd.sets.length; j++) {
                                    const set = rnd.sets[j];
                                    for (var k = 0; k < set.teams.length; k++) {
                                        c++;
                                        const team = this.getTeamById(brackets.teams, set.teams[k]);

                                        var score = 0;
                                        if (set.teams.length == 1 && rnd.id == numRounds) {
                                            score = 'Winner';
                                        }
                                        else if (set.teams.length == 1) {
                                            score = 'Free Win';
                                        }
                                        else if (team) {
                                            for (var m = 0; m < set.matches.length; m++) {
                                                if (set.matches[m].winner == team.id) {
                                                    score++;
                                                }
                                            }
                                        } else {
                                            score = '';
                                        }

                                        const teamName = team ? team.name : '';
                                        const x = set.coords.pos[k].x;
                                        const y = set.coords.pos[k].y;
                                        const teamStyle = {position: 'absolute', top: y + 'px', left: + x + 'px'};
                                        const teamKey = 'teamBlock-' + i + '-' + c;
                                        const scoreStyle = 'div-team-score';
                                        contentRows.push(
                                            <div key={teamKey} style={teamStyle}>
                                                <div className='div-team'>
                                                    {teamName}
                                                </div>
                                                <div className={scoreStyle}>
                                                    {score}
                                                </div>
                                            </div>
                                        );

                                                                    
                                        if (i > 0) {
                                            const prevRnd = brackets.rounds[i-1];
                                            for (var s = 0; s < prevRnd.sets.length; s++) {
                                                const prevSet = prevRnd.sets[s];
                                                if (prevSet.next_set == set.id && prevSet.id == c + 1) {
                                                    for (var p = 0; p < prevSet.coords.pos.length; p++) {
                                                        lineId++;

                                                        const prevPos = prevSet.coords.pos[p];    
                                                        
                                                        const lineColor = prevSet.winner > 0 && prevSet.winner == prevSet.teams[p] ? 'green' : 'black';

                                                        const x1 = prevPos.x + 120;
                                                        const y1 = prevPos.y + 40/2;
                                                        const x2 = set.coords.pos[k].x;
                                                        const y2 = set.coords.pos[k].y + 40/2;
                                                        const lineKey = 'bracket-line-' + lineId;
                                                        if (y2 > maxHeight)
                                                            maxHeight = y2;
                                                        if (x2 > maxWidth)
                                                            maxWidth = x2;
                                                        lines.push(
                                                            <line key={lineKey} x1={x1} y1={y1} x2={x2} y2={y2} stroke={lineColor} strokeWidth='2'/>
                                                        );            
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        maxHeight = maxHeight + 100;
                        const totalHt = (maxHeight + 100) + 'px';                        

                        maxHeight = maxHeight + 'px';
                        maxWidth = maxWidth + 'px';

                        subContent = (
                            <div className='w3-margin'>
                                <div className='div-brackets-container' style={{'height': totalHt}}>
                                    <div className='div-title-row'>
                                        {titleRow}
                                    </div>
                                    <div className='div-main-rows'>
                                        <svg height={maxHeight} width={maxWidth}>
                                        {lines}
                                        </svg>
                                        {contentRows}
                                    </div>
                                </div>
                            </div>
                        );    
                    }

                } else { // INFO
                    const infoUrl = tournament.url;
                      
                    subContent = (
                    <div className='w3-margin' style={{'fontFamily': null, 'fontWeight': null}} dangerouslySetInnerHTML={{__html: this.state.infoHtml}}>
                    </div>
                    );
                }
                
                const linkInfo = '/tournaments/' + tournament_id + '/';
                const linkPlayers = '/tournaments/' + tournament_id + '/players';
                const linkBrackets = '/tournaments/' + tournament_id + '/brackets';

                const myUserId = localStorage.getItem('user_id')
                var myStatus = -1
                if (myUserId != undefined && tournament.players) {
                    const myUser = tournament.players.find((user) => user.id === myUserId);
                    if (myUser) myStatus = myUser.status
                }

                const creationDate = moment.utc(tournament.date_created, "YYYY-MM-DD HH:mm:ss")
                const eventDate = moment.utc(tournament.date_event, "YYYY-MM-DD HH:mm:ss")
                const today = moment.utc()

                const isTournyActive = tournament.status == 1
                var signUpStatus = this.isSignUpAllowed(tournament, creationDate, eventDate, today, myStatus)
                var canCheckIn = this.isCheckInAllowed(tournament, creationDate, eventDate, today, myStatus)

                const eventDay = eventDate.format("ddd, DD MMM, YYYY")
                const eventTime = eventDate.format("HH:mm:ss") + " LOCAL"

                var eventStatus = 'Inactive'
                var eventStatusColor = 'lightgreen'
                switch (tournament.status) {
                    case 4: 
                        eventStatus = 'Completed'
                        eventStatusColor = 'Black'
                        break
                    case 3:
                        eventStatus = 'In-Progress'
                        eventStatusColor = 'darkgreen'
                        break
                    case 2:
                        eventStatus = 'Cancelled'
                        eventStatusColor = 'orangered'
                        break
                    case 1:
                        eventStatus = 'Active'
                        eventStatusColor = 'orange'
                        break
                }

                content = (
                    <div className='TournamentDetails'>
                        <div className='row'>
                            <div className='col-lg-2 col-sm-6 div-tournament-id'>
                                <div className='txt-tournament-id'>
                                    Tournament #{tournament.id}
                                </div>
                            </div>
                            <div className='col-lg-10 col-sm-6 div-tournament-title'>
                                <div className='title-tournament'>
                                    {tournament.name}
                                </div>
                                <div className='summary-tournament'>
                                    {tournament.summary}
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-4'>
                                <div className='div-tournament-countdown'>
                                    <div>STATUS</div>
                                    <div className='txt-tournament-countdown'>
                                        <span style={{color: eventStatusColor}}>
                                            {eventStatus}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='div-tournament-countdown'>
                                    <div>COUNTDOWN</div>
                                    <div className='txt-tournament-countdown'>
                                        <span style={{color: this.state.countdownColor}}>
                                            {this.state.countdownTxt[0]}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='div-tournament-date'>
                                    <div>EVENT DATE</div>
                                    <div className='txt-tournament-date'>
                                        <span style={{color: this.state.countdownColor}}>
                                            {eventDay}
                                        </span>
                                    </div>
                                    <div className='txt-tournament-date'>
                                        <span style={{color: this.state.countdownColor}}>
                                            {eventTime}
                                        </span>
                                    </div>                                    
                                </div>

                            </div>
                        </div>

                        <div className='tournament-btns'>
                            {isTournyActive && canCheckIn && <Button className='btn-checkin' color='success' onClick={this.onCheckInClick}>Check In!</Button>}
                            {isTournyActive && signUpStatus > -1 && <Button className='btn-signup' color={signUpStatus == 0 ? 'success' : 'info'} onClick={() => this.onSignUpClick(signUpStatus > 0)}>{signUpStatus == 0 ? 'Sign Up!' : 'Sign Out'}</Button>}
                        </div>

                        <Nav tabs fill className='root-nav'>
                            <NavLink to={linkInfo} className='root-nav-link'>Info</NavLink>
                            <NavLink to={linkPlayers} className='root-nav-link'>Players</NavLink>
                            <NavLink to={linkBrackets} className='root-nav-link'>Brackets</NavLink>
                        </Nav>
                        <div>
                            <div className='background-image' style={{backgroundImage: `url(${tournament.img})`}} />
                            <div>
                                {subContent}
                            </div>
                        </div>
                        <Popup modal={this.state.showPopup} text={this.state.popupMsg} />
                    </div>
                )

            } else {
                content = null;
            }
        }

        return (
            <div className='w3-margin'>
                {content}
            </div>            
        );     
    }

}

export default TournamentDetails;