import React, { Component, createRef } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { red, blue, grey } from '@material-ui/core/colors'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import './ReplayDetails.css';
import './StatsDetails.css';
import ImageUpload from './ImageUpload';
import Helpers from '../Helpers.js';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { searchUnits, fetchStatsByName, modifyStats, postData, setItemSelection, setFileAttachment, removeFileAttachment } from '../redux-actions/statsdetails';
import { Box, Tab, Tabs } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
const qs = require('query-string');


const IMG_PORTRAIT_HEIGHT = 120;

const StatsItem = (props) => {
    const myData = objGet(props.data, props.path)
    const otherData = objGet(props.otherData, props.path)
    const key = props.title

    //console.log(">>> StatsItem = " + key, props.path)
    if (!myData) {
        return []
    }
    if (isArray(myData)) {
        if (myData.length == 0) return []
        const res = []
        for (var k in myData) {
            const newPath = props.path + '.' + k
            res.push(<StatsItem path={newPath} title={''} data={props.data} otherData={props.otherData}
            readOnly={true} showPopup={props.showPopup} onStatChange={(event) => this.onStatChange(event, newPath)} />)
        }
        const tooltip = props.hint ? (<Tooltip title={props.hint}><div key='key-attr-weap-title' className='tooltip1'>&#x3F;</div></Tooltip>) : null
        return (
            <div key={props.path}>
                <span className='stats-details-subtitle'>{props.title}{tooltip}</span>
                <ul className='stats-details-section-list'>{res}</ul>
            </div>)          
    }
    else if (isValidObject(myData) && !myData.label) {
        const res = []
        for (var k in myData) {
            const newPath = props.path + '.' + k
            res.push(<StatsItem path={newPath} title={k} data={props.data} otherData={props.otherData}
                readOnly={true} showPopup={props.showPopup} onStatChange={(event) => this.onStatChange(event, newPath)} />)
        }
        const tooltip = props.hint ? (<Tooltip title={props.hint}><div key='key-attr-weap-title' className='tooltip1'>&#x3F;</div></Tooltip>) : null
        return (
            <div key={props.path}>
                <span className='stats-details-subtitle'>{props.title}{tooltip}</span>
                <ul className='stats-details-section'>{res}</ul>
            </div>)          
    }

    const isObj = isValidObject(myData)
    const isOtherObj = isValidObject(otherData)
    var value = isObj ? myData.value : myData
    var otherValue = isObj && isOtherObj ? otherData.value : undefined

    var label = isObj ? myData.label : key

    var isHighlighted = false
    var isNumeric = true
    var valueTooltip = []
    var keyTooltip = []

    var class_title = isObj && myData.minor ? 'stats-details-small' : ''
    var class_value = isObj && myData.minor ? 'stats-details-value stats-details-small' : 'stats-details-value'
    const class_mult = isObj && myData.minor ? 'stats-details-mult stats-details-small' : 'stats-details-mult'

    var class_col1 = 'col-3'
    var class_col2 = 'col-6'
    var class_col3 = 'col-3'

    var class_value_extra = ' stats-details-value-numeric'

    if (isObj) {          
        if (myData.ignorable && (value == undefined || value == null || (myData.default && value == myData.default))) {
            return null
        }

        if (myData.type == 'list') {
            var res = []
            for (var j in value) {
                var val
                var hint
                if (isValidObject(value[j])) {
                    val =  isValidObject(value[j]) ? value[j].name : null
                    hint = isValidObject(value[j]) ? value[j].hint : null
                } else {
                    val = value[j]
                    hint = null
                }
                const listTooltip = hint ? (<Tooltip title={hint}><div className='tooltip1' onClick={() => props.showPopup(hint)}>&#x3F;</div></Tooltip>) : null
                res.push(
                    <li style={{listStylePosition:'outside'}}>{val}{listTooltip}</li>
                )
            }
            if (res.length > 0) res = (<ul style={{listStyle:'inside'}}>{res}</ul>)
            value = res
        } else {
            if (myData.default && (value == undefined || value == null)) {
                value = myData.default
            }    
    
            if (myData.type == 'perc') {
                value = value + '%'
            } else if (myData.type == 'time') {
                value = value + 's'           
            } else if (myData.type == 'cost') {
                value = '$' + value       
            } else if (myData.type == 'string') {
                isNumeric = false
                class_col1 = 'col-3'
                class_col2 = 'col-9'
                class_col3 = 'col-0'
                class_value_extra = ' stats-details-value-string'        
            }
    
            if (myData.relative) {
                if (getNum(value) >= 0 && !value.toString().startsWith('+')) {
                    value = '+' + value
                }
            }    
        }

        if (myData.desc) {
            valueTooltip = (<Tooltip title={myData.desc}><div className='tooltip1' onClick={() => props.showPopup(myData.desc)}>&#x3F;</div></Tooltip>)        
            isHighlighted = true        
        }        

        if (myData.hint) {
            keyTooltip = (<Tooltip title={myData.hint}><div className='tooltip1' onClick={() => props.showPopup(myData.hint)}>&#x3F;</div></Tooltip>)
        }        

    } else {
        class_col1 = 'col-3'
        class_col2 = 'col-9'
        class_col3 = 'col-0'
        class_value_extra = ' stats-details-value-string'        
    }

    class_value += class_value_extra
    class_title += ' ' + class_col1

    var diff = null
    var mult = null
    if (value && otherValue != null) {
        var n1
        var n2
        if (!myData.negative) {
            n1 = getNum(value)
            n2 = getNum(otherValue)
        } else {
            n1 = getNum(otherValue)
            n2 = getNum(value)
        }

        if (n1 && n2) {
            if (n1 < n2) {
                diff = -1
                const m = Math.round((1 - n1 / n2) * 100)    
                mult = `-${m}%`
            } else if (n1 > n2) {
                diff = 1
                const m = Math.round((n1 / n2 - 1) * 100)                
                mult = `+${m}%`
            } else {

            }
        }
    }

    const color = diff == 1 ? 'green' : diff == -1 ? 'red' : 'transparent'

    const valueStyle = isHighlighted ? { color: '#2196f3', fontWeight: '500' } : null

    //console.log(myData)
    
    return (
        <li key={props.path} className='row'>
            <div className={class_title}>{label}{keyTooltip}</div>
            <div className={class_col2}>
                <span id={props.inputId} className={class_value} style={valueStyle}>
                    {value}{valueTooltip}                     
                </span>      
            </div>
            <div className={class_col3}>
                <span style={{ color: color }} className={class_mult}>{mult}</span>     
            </div>        
        </li>     
    )
}

const categories = ['Unit/Structure']

const grayTheme = createTheme({ palette: { primary: grey } })
const blueTheme = createTheme({ palette: { primary: blue } })

class StatsDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {       
            page: this.props.page,
            open: [false],
            err: [null],
            tabValue: ['0', '0'],
            paramsLoaded: false,
            onEditClick: this.props.onEditClick,
            onSaveClick: this.props.onSaveClick,
            onTreeOpen: this.props.onTreeOpen,
            isSticky: false,
            bannerOffset: undefined,
            bannerHeight: undefined
        };

        this.onTabChange = this.onTabChange.bind(this)
        this.onRefresh = this.onRefresh.bind(this)
        this.setAutocompleteOpen = this.setAutocompleteOpen.bind(this)
        this.onSearchInputChange = this.onSearchInputChange.bind(this)
        this.onSearchSelectionChange = this.onSearchSelectionChange.bind(this)
        this.onTreeOpen = this.onTreeOpen.bind(this)
        this.onPatchChange = this.onPatchChange.bind(this)
        this.onCategoryChange = this.onCategoryChange.bind(this)
        this.onStatChange = this.onStatChange.bind(this)
        this.pushQueryParams = this.pushQueryParams.bind(this)
        this.onUpgradeToggle = this.onUpgradeToggle.bind(this)
        this.addFile = this.addImgFile.bind(this)
        this.onImgClick = this.removeImg.bind(this)
        this.onEditCallback = this.onEditCallback.bind(this)
        this.handleScroll = this.handleScroll.bind(this)

        this.bannerRef = createRef();
    }
    
    setAutocompleteOpen(bool) {
        this.state.open[0] = bool
        this.setState({open: this.state.open})
    }

    onSearchSelectionChange(event, value) {
        const page = this.state.page

        this.props.dispatch(setItemSelection(page, value))

        const selectedItemId = value ? value.id : ''

        const searchValue = this.props.searchValues[page]
        const selectedPatch = this.props.selectedPatches[page]
        const selectedCategory = this.props.selectedCategories[page]

        this.props.dispatch(fetchStatsByName(page, selectedItemId, selectedPatch, selectedCategory))
        this.pushQueryParams(selectedItemId, searchValue, selectedPatch, selectedCategory)
    }

    onSearchInputChange(event) {
        var text = event.target.value

        const page = this.state.page
        const searchLoading = this.props.searchLoading[page]
        const selectedPatch = this.props.selectedPatches[page]
        const selectedCategory = this.props.selectedCategories[page]
        
        this.props.dispatch(searchUnits(this.state.page, text, selectedPatch, selectedCategory, searchLoading))
    }

    onTreeOpen() {
        const page = this.state.page
        const searchLoading = this.props.searchLoading[page]
        const selectedPatch = this.props.selectedPatches[page]
        const selectedCategory = this.props.selectedCategories[page]

        this.state.onTreeOpen(page, selectedPatch, selectedCategory, this.pushQueryParams)
    }

    onPatchChange(event) {
        const patchValue = event.target.value
       
        const page = this.state.page        
        const stats = this.props.stats[page]
        const searchLoading = this.props.searchLoading[page]
        const searchValue = this.props.searchValues[page]
        const selectedItemId = this.props.selectedItems[page] ? this.props.selectedItems[page].id : ''
        const selectedCategory = this.props.selectedCategories[page]

        const oldPatchValue = this.props.selectedPatches[page]
        console.log('>>> PATCH CHANGE', oldPatchValue, patchValue)
        if (oldPatchValue != patchValue) {
            this.props.dispatch(searchUnits(page, searchValue, patchValue, selectedCategory, searchLoading))
            this.pushQueryParams(selectedItemId, searchValue, patchValue, selectedCategory)
            if (stats) {
                this.props.dispatch(fetchStatsByName(page, stats.id, patchValue, selectedCategory))
            }            
        } else {
            this.props.dispatch(searchUnits(page, searchValue, patchValue, selectedCategory, true))            
        }
    }

    onCategoryChange(event) {      
        const value = event.target.value

        const page = this.state.page

        const searchLoading = this.props.searchLoading[page]
        const searchValue = this.props.searchValues[page]
        const selectedItemId = this.props.selectedItems[page] ? this.props.selectedItems[page].id : ''
        const selectedPatch = this.props.selectedPatches[page]

        const oldValue = this.props.selectedCategories[page]

        if (oldValue != value) {
            this.props.dispatch(searchUnits(page, searchValue, selectedPatch, value, searchLoading))     
            this.pushQueryParams(selectedItemId, searchValue, selectedPatch, value)
        } else {
            this.props.dispatch(searchUnits(page, searchValue, selectedPatch, value, true))     
        }
    }

    onUpgradeToggle(data, u) {
        if (data && data.upgrades && u) {
            const page = this.state.page

            const selectedItemName = data.id
            const selectedCategory = this.props.selectedCategories[page]
            const selectedPatch = this.props.selectedPatches[page]

            const upgrades = Object.values(data.upgrades)
            var selectedIds = {
                0: upgrades.filter(p => p.is_enabled && p.type == 0).map(p => p.id),
                1: upgrades.filter(p => p.is_enabled && p.type == 1).map(p => p.id)
            }

            u.is_enabled = !u.is_enabled
            if (u.is_enabled) {
                selectedIds[u.type] = [u.id].concat(selectedIds[u.type])
            } else {
                selectedIds[u.type] = selectedIds[u.type].filter(p => p != u.id)
            }

            var selectedUpgradeIds = selectedIds[0]
            var selectedPowerIds = selectedIds[1]
            
            this.props.dispatch(fetchStatsByName(page, selectedItemName, selectedPatch, selectedCategory, selectedUpgradeIds.join(','), selectedPowerIds.join(',')))
        }
    }

    pushQueryParams(selectedId, searchValue, selectedPatch, selectedCategory) {
        const page = this.state.page

        var params = qs.parse(this.props.location.search, {ignoreQueryPrefix: true})
        params[`patch[${page}]`] = selectedPatch
        params[`type[${page}]`] = selectedCategory
        params[`search[${page}]`] = searchValue
        params[`name[${page}]`] = selectedId

        this.props.history.replace({
            pathname: this.props.location.path,
            search: `?${qs.stringify(params)}`
          })
    }

    onStatChange(event, isNumeric) {
        event.persist()

        const data = this.props.stats[this.state.page]
        const type = this.props.statsTypes[this.state.page]
        const patch = data.patch

        if (event.target.id) {
            var key = event.target.id.replace('inp-', '')       
            var spl = key.split('-')
            var addr =  data
            for (var i = 0; i < spl.length - 1; i++) {
                addr = addr[spl[i]]
            }
            var value = event.target.value.trim()
            if (isNumeric) {
                value = value.replace(/\D/g,'')
            }
            if (value.toLowerCase() == 'n/a') {
                value = ''
            }
            addr[spl[spl.length - 1]] = value   

            this.props.dispatch(modifyStats(data, this.state.page, type, patch));        
        }
    }

    onEditCallback(myStats, newContent) {
        myStats.summary = newContent
    }

    componentWillReceiveProps(props) {        
        console.log('>>> WILL RECEIVE PROPS', props)
        if (props && props.location && props.location.search && props.location.search.length > 1) {
            if (props.metadata && props.metadata.patch_sets && !this.state.paramsLoaded) {
                const params = qs.parse(props.location.search, {ignoreQueryPrefix: true});            
                const page = this.state.page
                        
                var selectedId, searchValue, selectedPatch, selectedCategory
                selectedId = searchValue = selectedPatch = selectedCategory = ''

                console.log('>>> PARAMS: ', params)

                for (var k in params) {
                    if (k.toLowerCase() == `patch[${page}]`) {
                        for (var p  in props.metadata.patch_sets) {
                            const patch = props.metadata.patch_sets[p]
                            if (patch.name == params[k]) {
                                if (params[k] != this.props.selectedPatches[page]) {
                                    selectedPatch = params[k]
                                }
                                break
                            }
                        }
                    }
                    else if (k.toLowerCase() == `type[${page}]`) {
                        selectedCategory = params[k]                        
                    }
                    else if (k.toLowerCase() == `search[${page}]`) {
                        if (params[k] != this.props.searchValues[page]) {
                            searchValue = params[k]
                        }
                    }  
                    else if (k.toLowerCase() == `name[${page}]`) {
                        if (!this.props.selectedItems[page] || params[k] != this.props.selectedItems[page].id) {
                            selectedId = params[k]
                        }
                    }
                }

                this.setState({paramsLoaded: true})
                if (selectedPatch || searchValue || selectedId || selectedCategory) {
                    console.log('>>> PRESELECTED DATA FOUND FROM URL == ' + selectedCategory + ", " + selectedPatch + ", " + searchValue + ", " +  selectedId)
                    setTimeout(() => {
                        this.props.dispatch(searchUnits(page, searchValue, selectedPatch, selectedCategory))
                        this.props.dispatch(fetchStatsByName(page, selectedId, selectedPatch, selectedCategory))
                    }, 250)                    
                }
            }
        }    
    }

    removeImg(isSuperAdmin, data, event) {
        if (!isSuperAdmin) return
        const page = this.state.page
        if (data.icon || this.props.selectedFiles[page]) {
            this.props.dispatch(removeFileAttachment(page))
        }
    }

    addImgFile(fileInput) {
        fileInput.persist()

        var files = fileInput.target.files;
        if (files && files.length == 1) {            
            var file = files[0];
            var imageType = /image.*/;     
            if (!file.type.match(imageType)) {
                return;
            }           

            const self = this
            const page = this.state.page

            var reader = new FileReader();
            reader.onload = function(e) { 
                console.log(e.target.result)
                self.props.dispatch(setFileAttachment(page, file, e.target.result))
            };
            reader.readAsDataURL(file);
        }
    }

    onRefresh(page, data, type) {
        this.props.dispatch(fetchStatsByName(page, data.id, data.patch, type))
    }

    onTabChange(page, newValue) {
        this.state.tabValue[page] = newValue
        this.setState({
                tabValue: this.state.tabValue
            }            
        )
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    
    handleScroll() {
        const banner = this.bannerRef.current;
        const bannerHeight = IMG_PORTRAIT_HEIGHT
        if (bannerHeight && banner) {
            const relPos = banner.getBoundingClientRect().top
            if (relPos <= -bannerHeight * 4) {
                this.setState({
                    isSticky: true,
                });
            } else {
                this.setState({
                    isSticky: false,
                });
            }
        }
    }

    render() {
        const { metadata, stats, statsTypes, searchLists, searchValues, selectedPatches, selectedCategories, statsLoading, searchLoading, selectedFiles, selectedFilesData, selectedFileValues } = this.props

        const myPage = this.state.page

        const myStats = stats[myPage]
        const myStatsType = statsTypes[myPage]

        const otherStats = stats[1 - myPage]
        const otherStatsType = statsTypes[1 - myPage]

        const mySearchList = searchLists[myPage] ? searchLists[myPage] : []
        const mySearchValue = searchValues[myPage]
        const myStatsLoading = statsLoading[myPage]
        const mySearchLoading = searchLoading[myPage]

        const mySelectedCategory = selectedCategories[myPage]        
        const mySelectedPatch = selectedPatches[myPage]

        const myFile = selectedFiles[myPage]
        const myFileData = selectedFilesData[myPage]

        const myTab = this.state.tabValue[myPage]
        
        var data = myStats
        const type = myStatsType
    
        const treeDisabled = !metadata || mySelectedCategory != 1

        const isSuperAdmin = Helpers.isSuperAdmin()

        const canCompare = myStatsType == otherStatsType

        const isSticky = this.state.isSticky

        var unitPortrait = null
        if (myFileData) {
            unitPortrait = myFileData
        }

        const patchList = []     
        const patchNames = []   
        if (metadata && metadata.patch_sets) {
          for (var i = 0; i < metadata.patch_sets.length; i++) {
                const p = metadata.patch_sets[i]
                if (p.name == 'patch102x' || p.is_addon || !p.is_hosted)
                    continue
                patchNames.push(p.name)
                const html = (<MenuItem value={p.name}>{p.display_name}</MenuItem>)
                patchList.push(html)
          }
        }

        const categoryList = []
        for (var i = 0; i < categories.length; i++) {
            const html = (<MenuItem value={i + 1}>{categories[i]}</MenuItem>)
            categoryList.push(html)
        }

        var content = []
                
        if (data) {
            if (!unitPortrait)
                unitPortrait = data.icon            

            var patchDispName = ''
            if (metadata && metadata.patch_sets && metadata.patch_sets.length > 0) {
                const selectedPatch = metadata.patch_sets.find((p) => p.name.toLowerCase() == data.patch.toLowerCase())
                if (selectedPatch) patchDispName = selectedPatch.display_name
            }

            const fileInputStyle = !isSuperAdmin || unitPortrait || (myFile != null) || !data ? {display: 'none'} : {display: 'block'} 

            const portraitImg = unitPortrait ? (
                <img className={`stats-details-portrait`}
                    src={unitPortrait}  onClick={(event) => this.removeImg(isSuperAdmin, data, event)} />                        
            ) : (
                <img className={`stats-details-portrait`}
                    src={require('../res/unknown_map.png')} />                        
            )

            const portrait_html = (
                <div className='stats-details-portrait-container' ref={this.bannerRef}>
                    {portraitImg}
                    <input id='input-portrait' className='stats-details-portrait-input' style={fileInputStyle} type="file" accept="image/*" 
                        defaultValue={selectedFileValues[myPage]}
                        onInput={(event)=> { 
                            this.addImgFile(event) 
                        }}                                    
                    />
                </div>
            )

            if (data.display_name) {
                content.push(
                    <div className={`stats-details-header`} key='key-stats-details-header'>
                        <Button className='stats-details-refresh-btn' onClick={() => this.onRefresh(myPage, data, myStatsType)}>
                            <img src={require('../res/refresh.png')} width='20px' height='20px' />
                        </Button>
                        <div className='stats-details-header-title'><h2>{data.display_name}</h2></div>
                        {portrait_html}
                        <div className='stats-details-header-patch'><h4>{patchDispName}</h4></div>
                    </div>
                )
                if (isSticky) {
                    const stickyImg = (unitPortrait ?                                
                        <img className='stats-details-portrait-sticky' src={unitPortrait} /> :
                        <img className='stats-details-portrait-sticky' src={require('../res/unknown_map.png')} />
                    )
                    const stickyClass = myPage == 0 ? 'stats-details-header-sticky-left' : 'stats-details-header-sticky-right'                    
                    content.push(
                        <div className={`${stickyClass}`} key='key-stats-details-header'>
                            <Button className='stats-details-refresh-btn' onClick={() => this.onRefresh(myPage, data, myStatsType)}>
                                <img src={require('../res/refresh.png')} width='20px' height='20px' />
                            </Button>
                            <div className='stats-details-content-sticky'>
                                {stickyImg}
                                <div>
                                    <div><h4>{data.display_name}</h4></div>
                                    <div className='stats-details-header-patch-sticky'><h5>{patchDispName}</h5></div>
                                </div>
                            </div>
                        </div>                        
                    )
                }
            }       

            const tabData = []
            if (data.data) {
                var upgrades_html = []
                if (data.upgrades && Object.keys(data.upgrades).length > 0) {
                    for (var u in data.upgrades) {
                        const upg = data.upgrades[u]
                        if (upg) {
                            const btnTheme = upg.is_enabled ? blueTheme : grayTheme
                            const key = 'key-upgrade-' + u
    
                            upgrades_html.push(
                                <div key={key} style={{display: 'inline-block', marginRight: '5px'}}>
                                    <MuiThemeProvider theme={btnTheme}>
                                        <Button color='primary' onClick={() => this.onUpgradeToggle(data, upg)}>
                                            <div style={{display: 'inline'}}>
                                                <img src={upg.icon} style={{height: '25px', marginRight: '5px', objectFit: 'contain'}} />                                                
                                            </div>
                                            <div style={{display: 'inline'}}>
                                                {upg.name}                                                
                                            </div>
                                        </Button>
                                    </MuiThemeProvider>
                                </div>
                            )
                        }
                    }
                    upgrades_html = (<div key='key-upgrades'><span className='stats-details-subtitle'>Available Upgrades</span><div className='stats-details-note'>Click To Enable/Disable</div><div className='stats-details-section'>{upgrades_html}</div></div>)
                    tabData.push(upgrades_html)
                }

                for (var k in data.data) {
                    tabData.push(
                        <StatsItem title={k} path={k} data={data.data} otherData={otherStats ? otherStats.data : null}
                            readOnly={!isSuperAdmin} showPopup={this.props.showPopup} onStatChange={(event) => this.onStatChange(event, k)} />                        
                    )
                }
            }

            const tabDesc = []
            if (data.type_desc) {
                tabDesc.push(
                    <div className='stats-details-desc'>
                        <span className='stats-details-subtitle'>TYPE DESCRIPTION</span>
                        <div>
                            {data.type_desc}
                        </div>
                    </div>
                )    
            }
            if (data.desc) {
                tabDesc.push(
                    <div className='stats-details-desc'>
                        <span className='stats-details-subtitle'>DESCRIPTION</span>
                        <div>
                            {data.desc}
                        </div>
                    </div>
                )    
            }
            if (data.summary) {
                tabDesc.push(
                    <div className='stats-details-desc'>
                        <span className='stats-details-subtitle'>ADDITIONAL INFO</span>
                        <div>
                            {data.summary}
                        </div>
                    </div>
                )    
            }

            content.push(
                <TabContext value={myTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={(event, newValue) => this.onTabChange(myPage, newValue)}>
                            <Tab label="Stats" value="0" />
                            <Tab label="Description" value="1" />
                        </TabList>
                    </Box>
                    <TabPanel value="0">{tabData}</TabPanel>
                    <TabPanel value="1">{tabDesc}</TabPanel>
                </TabContext>
            )

            const btns = (
                <div className='btn-unit-details-save'>
                    <Button variant='contained' color='secondary' onClick={() => this.state.onEditClick(myStats, (myStats, newContent) => this.onEditCallback(myStats, newContent))} style={{margin: '5px'}}>Edit</Button>
                    <Button variant='contained' color='primary' onClick={() => this.state.onSaveClick(myPage, myStatsType, myStats, myFile)} style={{margin: '5px'}}>Save</Button>
                </div>)

            content = (<div>{content}{isSuperAdmin ? btns : null}</div>)

        } else {
            content = (<span className='stats-details-content-div'>Select A Unit To View Details Here.</span>)
        }

        return (
                <Card style={{backgroundColor: '#ececec'}}>
                    <CardContent>
                        <div className=''>
                            <div className='row w3-margin'>
                                <div className='col-12 col-md-12 col-sm-12' style={{textAlign: 'center'}}>
                                    <InputLabel id="input-patch-label" style={{color: 'black'}}>Patch</InputLabel>
                                    <Select
                                        style={{color: 'black', minWidth: '150px'}}
                                        labelId="select-patch-label"
                                        id="select-patch"      
                                        children={patchList}   
                                        value={this.props.selectedPatches[myPage]}
                                        onChange={this.onPatchChange}   
                                        autoWidth                                    
                                        />

                                    <InputLabel id="input-category-label" style={{color: 'black', marginTop: '15px'}}>Object Type</InputLabel>
                                    <Select
                                        style={{color: 'black', minWidth: '150px'}}
                                        labelId="select-category-label"
                                        id="select-category"      
                                        children={categoryList}   
                                        value={this.props.selectedCategories[myPage]}
                                        onChange={this.onCategoryChange}     
                                        autoWidth                                     
                                        />
                                </div>     
                            </div>

                            <div className='w3-margin'>
                                <Autocomplete
                                    id="autcomplete-unit"
                                    style={{ color: 'black', minWidth: 200 }}
                                    open={this.state.open[0]}
                                    onOpen={() => {
                                        this.setAutocompleteOpen(true);
                                    }}
                                    onClose={() => {
                                        this.setAutocompleteOpen(false);
                                    }}
                                    freeSolo
                                    onChange={this.onSearchSelectionChange}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    getOptionLabel={option => option.id}
                                    options={mySearchList}
                                    loading={mySearchLoading}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            style={{ color: 'white' }}
                                            label="Search Object"
                                            variant="outlined"
                                            onChange={this.onSearchInputChange}
                                            value={mySearchValue}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                <React.Fragment>
                                                    {mySearchLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />

                                <div className='btn-stats-details-tree'>
                                    <Button variant='outlined' color='secondary' onClick={this.onTreeOpen} disabled={treeDisabled}>OR Select from List</Button>
                                </div>
                            </div>

                            <div id='unit-details' className='stats-details-container-div'>
                                {myStatsLoading ? <div className='stats-details-progress-div'><div className='stats-details-progress-text'>Loading...</div><CircularProgress color="inherit" size={40} /></div> : content ? 
                                    content : null }
                            </div> 
                        </div>
                    </CardContent>
                </Card>


        );
    }    
}

function isValidObject(o) {
    try {
        const p = Object.getPrototypeOf(o).toString();
        return p.length > 1;
    } catch (err) {
        return false;
    }
}

function getNum(a, def = 0) {
    if (!a) {
        return def;
    }
    return Number((a.toString()).replace(/[^0-9eE\.\+\-]/g, ""));
}

function objGet(obj, path) {
    if (!obj) return undefined;
    // Split the path string into an array of keys
    const keys = path.split('.');
    
    // Initialize the result to the input object
    let result = obj;

    // Iterate through each key in the path
    for (const key of keys) {
        // Check if the current key exists in the result object
        if (result && result.hasOwnProperty(key)) {
            // Update the result to the value of the current key
            result = result[key];
        } else {
            // Return undefined if any key in the path is missing
            return undefined;
        }
    }

    // Return the final result
    return result;
}

function isArray(o) {
    return Array.isArray(o)
}

const get = (p, o) =>
  p.reduce((xs, x) =>
    (xs && xs[x]) ? xs[x] : null, o)

const mapStateToProps = (state) => {
    return {
      metadata: state.metadata.metadata,
      stats: state.statsdetails.stats,
      searchLists: state.statsdetails.searchLists,
      searchValues: state.statsdetails.searchValues,
      searchLoading: state.statsdetails.searchLoading,
      statsTypes: state.statsdetails.statsTypes,      
      statsLoading: state.statsdetails.statsLoading,
      selectedCategories: state.statsdetails.selectedCategories,
      selectedPatches: state.statsdetails.selectedPatches,
      selectedItems: state.statsdetails.selectedItems,
      selectedFiles: state.statsdetails.selectedFiles,
      selectedFilesData: state.statsdetails.selectedFilesData,
      selectedFileValues: state.statsdetails.selectedFileValues,
      statsTimestamp: state.statsdetails.timestamp
    }
  };
  
  export default withRouter(connect(mapStateToProps)(StatsDetails));