import {
    FETCH_TOURNAMENTS_BEGIN,
    FETCH_TOURNAMENTS_SUCCESS,
    FETCH_TOURNAMENTS_FAILURE
  } from '../redux-actions/tournaments';
  
  const initialState = {
    item: null,
    loading: false,
    error: null,
    timestamp: 0
  };
  
  export default function messageReducer(state = initialState, action) {
    switch(action.type) {
      case FETCH_TOURNAMENTS_BEGIN:
        return {
          ...state,
          loading: true,
          error: null
        };
  
      case FETCH_TOURNAMENTS_SUCCESS:
        return {
          ...state,
          loading: false,
          item: action.payload.tournament,
          timestamp: new Date().getTime()
        };
  
      case FETCH_TOURNAMENTS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          item: null,
          timestamp: new Date().getTime()
        };
  
      default:
        return state;
    }
  }