import React, { Component } from 'react';
import './FileDetailsPopup.css';
import { Button, Card,  CardBody } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField } from '@material-ui/core';
import Helpers from '../Helpers.js';


class FileDetailsPopup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: [false],
      patch: this.props.patch,
      versions: this.props.versions,
      onFileDownload: this.props.onFileDownload,
      onClosed: this.props.onClosed
    };

    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps(props) {
    this.state.modal = props.modal;
    this.state.patch = props.patch;
    this.state.versions = props.versions;
    this.setState({modal: this.state.modal, patch: this.state.patch, versions: this.state.versions});
    this.forceUpdate();
  }

  toggle() {
    this.state.modal[0] = !this.state.modal[0];
    this.setState({
      modal: this.state.modal
    });
    if (!this.state.modal[0]) this.state.onClosed()
  }

  render() {

    const stuff = [];
    const canRender = this.state.versions && this.state.versions.length > 0;
    if (canRender) {
      var isLatestFound = false;
      for (var i = 0; i < this.state.versions.length; i++) {
        const curPos = i;
        const version = this.state.versions[i];

        var isLatest = false;
        if (!isLatestFound && !version.is_test_version) {
          isLatestFound = true;
          isLatest = true;
        }

        var subTitle = null;
        if (isLatest) {
          subTitle = (<div className='div-latest-ver'>Latest Version!</div>);
        } else if (version.is_test_version) {
          subTitle = (<div className='div-test-ver'>Test Version</div>)
        }

        const desc = version.desc && version.desc.length > 0 ? version.desc : (<i>No description available</i>)

        const dateCreated = Helpers.convertDate(version.date_created, "D MMM YYYY HH:mm");

        const downloadName = this.state.patch.display_name + " " + version.version_name;

        const metaVersionId = version.id
        const metaPackName = this.state.patch.name

        const isExe = version.installer_file && version.installer_file.toLowerCase().endsWith(".exe")

        stuff.push(
          <Card key={'card-'+i} className='w3-margin'>
            <CardBody className='file-details-card'>
              <div>
                <div className='row mb-3 mr-1'>
                  <div className='col-6'>
                    <span className='div-file-date'>
                      {dateCreated}
                    </span>
                  </div>                
                  <div className='col-6'>
                    <div className='d-flex justify-content-end'>
                      <Button color='primary' onClick={() => this.props.onFileDownload(downloadName, metaVersionId, metaPackName, isExe)}>DOWNLOAD</Button>
                    </div>
                  </div>
                </div>
                <div className='row align-items-center mr-1'>
                  <div className='col-6 mb-3 mb-md-0'>
                    <span className='font-weight-bold text-ver-name'>
                      {version.version_name}
                    </span>
                  </div>
                  <div className='col-6 d-flex justify-content-end mb-3'>
                    {subTitle}
                  </div>
                </div>
                <div className='div-file-desc mr-1'>
                  {desc}
                </div>
              </div>
            </CardBody>
          </Card>
        );
      }
    }

    const patchName = this.state.patch ? this.state.patch.display_name : '';

    return (
      <div>
        <Dialog open={this.state.modal[0]} onClose={this.toggle} aria-labelledby="form-dialog-title" className={this.props.className}>
          <DialogTitle id="form-dialog-title"><b>{patchName}</b></DialogTitle>
          <DialogContent>
            <div>
              {stuff}
            </div>
          </DialogContent>
          <DialogActions>
            <div>
              <Button color='secondary' onClick={this.toggle}>Cancel</Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default FileDetailsPopup;
