import React, { Component } from 'react';
import MapDetails from '../components/MapDetails';
import Helpers from '../Helpers.js';
import SearchHeader from '../components/SearchHeader';
import ReplayComments from '../components/ReplayComments';
import UserDetailsPopup from '../components/UserDetailsPopup';
import Popup from '../components/Popup';
import { Progress } from 'reactstrap';

class Maps extends Component {

  constructor(props) {
    super(props);

    this.state = {
      maps: [],
      total_count: [0],
      page_cur: [0],
      page_max: [0],
      num_per_page: [0],
      page_goto: [''],
      search_text: '',
      selectedMap: null,
      showLoading: [true],
      err: '',
      showUserDetailsPopup: [false],
      showModal: [false],
      showCommentsModal: [false],
      comments: [],
      replay_id: [0],
      downloadUrl: null,
      is_data_loaded: [false]
    };

    this.getMapHtml = this.getMapHtml.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onCommentsClick = this.onCommentsClick.bind(this);
    this.onDownloadClick = this.onDownloadClick.bind(this);
    this.onLikeClick = this.onLikeClick.bind(this);
    this.onMapClick = this.onMapClick.bind(this);

    this.onNextClicked = this.onNextClicked.bind(this);
    this.onPrevClicked = this.onPrevClicked.bind(this);
    this.onPageGotoClicked = this.onPageGotoClicked.bind(this);
    this.onPageGotoChange = this.onPageGotoChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchClicked = this.onSearchClicked.bind(this);
  }

  getMapHtml() {
    var a = [];
    if (this.state.maps) {
      for (var i = 0; i < this.state.maps.length; i++) {
        const stuff = (
          <div key={this.state.maps[i].id}>
            <MapDetails data={this.state.maps[i]} onDownloadClick={this.onDownloadClick} onCommentsClick={this.onCommentsClick}
             onLikeClick={this.onLikeClick} onMapClick={this.onMapClick} />
          </div>
        );
        a.push(stuff);
      }
    }
    return a;
  }

  render() {
    const maps = this.getMapHtml();
    const stuff = this.state.showLoading[0] ? (<div className='w3-margin'><Progress bar animated value="100">Loading</Progress></div>) : maps;

    return (
      <div className='Replays'>
        <SearchHeader onNextClicked={this.onNextClicked} onPrevClicked={this.onPrevClicked} num_per_page={this.state.num_per_page} total_count={this.state.total_count}
          page_cur={this.state.page_cur} page_max={this.state.page_max} page_goto={this.state.page_goto} onPageGotoClicked={this.onPageGotoClicked}
          onPageGotoChange={this.onPageGotoChange} onSearchChange={this.onSearchChange} onSearchClicked={this.onSearchClicked} />
        <div className='w3-margin'>
          To upload your own maps, and for easy map installation and updates, get <a href={Helpers.CP_URL}>Command Post</a> and register an account.
        </div>
        <div>
          {stuff}
        </div>
        <UserDetailsPopup modal={this.state.showUserDetailsPopup} player={this.state.selectedMap} />
        <ReplayComments modal={this.state.showCommentsModal} replay_id={this.state.replay_id} comments={this.state.comments} />
        <Popup modal={this.state.showModal} title='Info' text={this.state.err} url={this.state.downloadUrl}  />
      </div>
    );
  }

  componentDidUpdate() {
    if (!this.state.is_data_loaded[0]) {
      this.fetchData();
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  static getDerivedStateFromProps(props, state) {
    const prevIsMatches = state.is_matches;
    state = {
      ...state,
      is_matches: props.location.pathname.toLowerCase().startsWith('/matches')
    }; 
    if (prevIsMatches != state.is_matches) {
      state.is_data_loaded[0] = false;
    }
    return state;
  }

  onPrevClicked() {
    if (this.state.page_cur[0] > 1)
      this.fetchData(this.state.page_cur[0] - 1);
  }

  onNextClicked() {
    if (this.state.page_cur[0] < this.state.page_max[0])
      this.fetchData(this.state.page_cur[0] + 1);
  }

  onPageGotoClicked() {
    this.fetchData(this.state.page_goto[0]);
  }
    
  onPageGotoChange(event) {
    this.state.page_goto[0] = event.target.value;
    this.setState({page_goto: this.state.page_goto});
  }

  onSearchChange(event) {
    this.state.search_text = event.target.value;
    this.setState({search_text: this.state.search_text});
    console.log('>>> ' + this.state.search_text)
  }

  onSearchClicked() {
    this.fetchData(this.state.page_cur[0]);
  }

  fetchData(pageTo = 0) {
    this.state.is_data_loaded[0] = true;
    this.state.showLoading[0] = true;

    fetch(Helpers.getBaseURL() + '/fetch_maps.php', {
      method: 'POST',
      headers: Helpers.addHeaders(),
      body: Helpers.appendParams('page_cur=' + pageTo + '&search_text=' + this.state.search_text)
    })
    .then(response => {
      var stuff = response.json();
      return stuff;
    })
    .then(data => {
      this.setState({maps: [], err: ''});      

      if (data.status == '1') {
        this.state.page_cur[0] = data.page_cur;
        this.state.page_max[0] = data.page_max;
        this.state.page_goto[0] = '';
        this.state.num_per_page[0] = data.num_per_page;
        this.state.total_count[0] = data.total_count;
        this.state.search_text = data.search_text;
      } else {
        this.state.err = data.message;
      }

      this.state.showLoading[0] = false;

      this.setState({maps: data.output, search_text: this.state.search_text, err: this.state.err, showLoading: this.state.showLoading});
    })
    .catch(function(error) {
      this.state.showModal[0] = true;
      this.state.showLoading[0] = false;
      this.setState({err: error.message, showModal: this.state.showModal, showLoading: this.state.showLoading});
    }.bind(this));
  }

  onMapClick(map) {
    if (map) {
      this.state.showUserDetailsPopup[0] = true;
      this.setState({showUserDetailsPopup: this.state.showUserDetailsPopup, selectedMap: map});
    }
  }

  onLikeClick(replay_id) {
    /*
    this.state.showLoading[0] = true;
    fetch(Helpers.getBaseURL() + '/fetch_replays.php', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: Helpers.appendParams('type=5&replay_id=' + replay_id)
    })
    .then(response => {
      var stuff = response.json();
      return stuff;
    })
    .then(data => {
      this.state.showLoading[0] = false;
      this.setState({maps: []});

      if (data.status == '1') {
        this.state.page_cur[0] = data.page_cur;
        this.state.page_max[0] = data.page_max;
        this.state.num_per_page[0] = data.num_per_page;
        this.state.total_count[0] = data.total_count;
      }
      this.setState({maps: data.output});
      this.state.showModal[0] = true;
      this.setState({err: data.message, showModal: this.state.showModal, downloadUrl: null});

      this.setState({showLoading: this.state.showLoading});
    })
    .catch(function(error) {
      this.state.showLoading[0] = false;
      this.setState({showLoading: this.state.showLoading});
    }.bind(this));
    */
  }

  onDownloadClick(map) {
    if (Helpers.isLoggedIn()) {
      fetch(Helpers.getBaseURL() + '/fetch_maps.php', {
        method: 'POST',
        headers: Helpers.addHeaders(),
        body: Helpers.appendParams('map_name=' + encodeURIComponent(map.name))
      })
      .then(response => {
        var stuff = response.json();
        return stuff;
      })
      .then(data => {
        if (data.status == '1') {
          if (data.output && data.output.length > 0) {
            var map = data.output[0];
            this.state.showModal[0] = true;
            this.setState({err: 'Ready to download!', showModal: this.state.showModal, downloadUrl: map.url});
          }
        }
      })
      .catch(function(error) {
        console.log(error.message);
      }.bind(this));
    } else {
      this.state.showModal[0] = true;
      this.setState({err: 'Access denied - login required!', showModal: this.state.showModal});
    }
  }

  onCommentsClick(replay_id) {
    this.state.showLoading[0] = true;
    fetch(Helpers.getBaseURL() + '/fetch_replays_comments.php', {
      method: 'POST',
      headers: Helpers.addHeaders(),
      body: Helpers.appendParams('replay_id=' + replay_id)
    })
    .then(response => {
      var stuff = response.json();
      return stuff;
    })
    .then(data => {

      this.setState({comments: [], err: ''});

      if (data.status == '1') {
        this.state.showCommentsModal[0] = true;
        this.setState({comments: data.output, replay_id: [data.replay_id], showCommentsModal: this.state.showCommentsModal});
      } else {
        this.state.showModal[0] = true;
        this.setState({err: data.message});
      }
      this.state.showLoading[0] = false;
      this.setState({showLoading: this.state.showLoading});
    })
    .catch(function(error) {
      console.log(error);
      this.state.showLoading[0] = false;
      this.setState({err: 'Oops! Something went wrong.', showLoading: this.state.showLoading});
    }.bind(this));
  }


}


export default Maps;
