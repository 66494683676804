import React from 'react';
import { Button } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField } from '@material-ui/core';
import Helpers from '../Helpers.js';

class EditStatsDetailsPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.modal,
      data: this.props.data,
      patch: this.props.patch,
      err: '',
      showLoading: [false]
    };

    this.toggle = this.toggle.bind(this)
    this.onSaveClick = this.onSaveClick.bind(this)
    this.onSaveSuccess = this.onSaveSuccess.bind(this)
    this.onKeyPress = this.onKeyPress.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
  }
    
  componentWillReceiveProps(props) {
    const newData = props.data
    //const newData = this.state.modal[0] ? this.state.data : props.data 
    this.setState({modal: props.modal, data: newData, err: props.err})
  }

  toggle() {
    this.state.modal[0] = !this.state.modal[0];
    this.setState({
      modal: this.state.modal,
    });
  }

  onKeyPress(e) {
    if(this.state.modal[0] && e.charCode == 13){
      this.onSaveClick()
    }
  }

  onInputChange(event) {
    this.setState({data: event.target.value})
  }
  
  onSaveClick() {
    var newContent = this.state.data

    this.onSaveSuccess()
    this.props.onSubmit(newContent)
  }

  onSaveSuccess() {
    this.setState({'err': '', 'showLoading': [false]});      
    this.toggle()      
  }

  componentDidMount() {
    Helpers.enableTextAreaTab("outlined-multiline-static")    
  }

  render() {
    const textAreaWidth = Helpers.isMobile() ? "250px" : "500px"

    return (     
      <Dialog open={this.state.modal[0]} onClose={this.toggle} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">EDIT DESCRIPTION</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edit Description for this object.
          </DialogContentText>
            <div style={{'width': textAreaWidth}}>              
              <textarea               
                id="outlined-multiline-static"
                style={{'width': '100%', overflowX: 'hidden'}}
                label="Object Description"
                rows="100"
                value={this.state.data}
                onKeyDown={Helpers.enableTextAreaTab}
                variant="outlined"
                onChange={this.onInputChange}
              />
            </div>
        </DialogContent>
        <DialogActions>
          <div>
            <div className='err-text center'>
                {this.state.err}
            </div>
            <Button onClick={() => this.onSaveClick()} color='primary' style={{'marginTop': '15px'}} >
              OK
            </Button>
          </div>
        </DialogActions>
      </Dialog>)
  }
  
}

export default EditStatsDetailsPopup;
