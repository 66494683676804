import React, { Component } from 'react';
import './Users.css';
import { Card, CardImg, CardHeader, CardText, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap';
import UserDetails from '../components/UserDetails.js';
import Helpers from '../Helpers.js';
import { Progress } from 'reactstrap';

const ChatMessage = (props) => {

  const curDate = Helpers.convertDate(props.obj.created_at);
  const avatar = Helpers.loadAvatar(props.obj.avatar);
  const message = Helpers.linkifyText(props.obj.message);

  return (
    <Card>
      <CardHeader>
        <div className='row'>
          <div className='col-6'>
            <img src={avatar} className='avatar-small' style={{'marginRight': '10px'}} />
            {props.obj.name}
          </div>
          <div className='col-6'>
            {curDate}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div dangerouslySetInnerHTML={{__html: message}} />
      </CardBody>
    </Card>

  );
};

class Chat extends Component {

  constructor(props) {
    super(props);

    this.state = {
      chat: [],
      inputMsg: '',
      disableSend: [true],
      showLoading: [false],
      err: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.getChat = this.getChat.bind(this);
    this.sendMsg = this.sendMsg.bind(this);
  }

  handleChange(event) {
    const msg = document.getElementById('msgInput').value.trim();
    if (msg && msg.length > 2) {
      this.state.disableSend[0] = false;
    } else {
      this.state.disableSend[0] = true;
    }
    this.setState({inputMsg: msg, disableSend: this.state.disableSend});
  }

  onReplyClick(i) {
    this.setState({followup_id: this.state.comments[i].id});
  }

  sendMsg() {
    if (!this.state.inputMsg || this.state.inputMsg.length == 0)
      return;

    fetch(Helpers.getBaseURL() + '/fetch_public_chat.php', {
      method: 'POST',
      headers: Helpers.addHeaders(),
      body: Helpers.appendParams('type=1' + '&message=' + this.state.inputMsg)
    })
    .then(response => {
      var stuff = response.json();
      return stuff;
    })
    .then(data => {

      if (data.status == '1') {
        document.getElementById('msgInput').value = '';
        this.handleChange(null);

        this.setState({chat: data.output, err: ''});
      } else {
        this.setState({'err': data.message});
      }

      this.setState({showLoading: [false]});
      this.forceUpdate();

    })
    .catch(function(error) {
      var err = "Oops! Something went wrong here.";
      this.setState({err: error.message, showLoading: [false]});
      this.forceUpdate();
    }.bind(this));
  }

  componentDidMount() {
    this.getChat(true);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getChat(showLoading = false) {
    if (showLoading) {
      this.state.showLoading[0] = true;
      this.setState({showLoading: this.state.showLoading});
    }

    fetch(Helpers.getBaseURL() + '/fetch_public_chat.php', {
      method: 'POST',
      headers: Helpers.addHeaders(),
      body: Helpers.appendParams('type=0')
    })
    .then(response => {
      var stuff = response.json();
      return stuff;
    })
    .then(data => {
      if (data.status == '1') {
        const s1 = JSON.stringify(data.output);
        const s2 = JSON.stringify(this.state.chat);
        if ((!s1 || !s2) || (s1 != s2) || (s1.length != s2.length)) {
          var err = '';
          var chat = [];
          if (data.status == '1') {
            chat = data.output;
          } else {
            err = data.message;
          }

          this.setState({chat: chat, err: err});

          if (!this.interval) {
            this.interval = setInterval(() => this.getChat(), 25000);
          }
        }
      } else {
        this.setState({chat: [], err: data.message});
      }

      this.state.showLoading[0] = false;
      this.setState({showLoading: this.state.showLoading});
    })
    .catch(function(error) {
      this.state.showLoading[0] = false;
      var err = "Oops! Something went wrong here.";
      this.setState({showLoading: this.state.showLoading, err: error.message});
    }.bind(this));
  }

  render() {
    const isLoading = this.state.showLoading[0];
    const stuff = [];
    var numActiveUsers = 0;
    if (!isLoading)
    {
      if (this.state.chat && this.state.chat.length > 0) {
        for (var i = 0; i < this.state.chat.length; i++) {
          const curPos = i;
          const chatObj = this.state.chat[curPos];
          const uid = 'chatmsg-' + curPos;
          stuff.push(
            <div key={uid}>
              <ChatMessage obj={chatObj} />
            </div>
          );
        }
      } else if (!Helpers.isLoggedIn()) {
        stuff.push(
          <div className='Users w3-margin'>
          </div>
        );
      }
    } else {
      stuff.push(<div className='w3-margin'><Progress bar animated value="100">Loading</Progress></div>);
    }

    return (
      <div className='Users w3-margin'>
        <div className='w3-margin'>
          <span>Enter message to send [minimum length 3 characters]</span>
          <div className='flex-container'>
            <input className='flex-4 input' id='msgInput' onChange={this.handleChange} />
            <Button className='flex-1' type='submit' disabled={this.state.disableSend[0]} onClick={this.sendMsg}>Send</Button>
          </div>
          <div className='err-text-large'>
            {this.state.err}
          </div>
          <br />
          <div>
            {stuff}
          </div>
        </div>
      </div>
    );
  }

}


export default Chat;
