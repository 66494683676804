import React from 'react';
import './DonationsPopup.css';
import { Button } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Helpers from '../Helpers';

class DonationsPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.modal,
      data: this.props.data,  
    };

    this.toggle = this.toggle.bind(this)
    this.formatCash = this.formatCash.bind(this)
    this.getAmount = this.getAmount.bind(this)
    this.getBalance = this.getBalance.bind(this)
  }
    
  componentWillReceiveProps(props) {
    this.setState({modal: props.modal, data: props.data})
  }

  toggle() {
    this.state.modal[0] = !this.state.modal[0];
    this.setState({
      modal: this.state.modal,
    });
  }

  formatCash(val, currency)
  {
      const sign = val >= 0 ? "" : "-";
      val = Math.abs(val);
      return `${sign}${currency}${Helpers.formatCurrency(val)}`;
  }

  getAmount(data) {
    var s = this.formatCash(data.amount, data.amount_currency);
    if (data.fee != 0)
    {
        s += ` [${this.formatCash(data.fee, data.fee_currency)}]`;
    }
    return s;
  }

  getBalance(data) {
    if (data.balance <= 0)
    {
        return 'N/A';
    }
    return this.formatCash(data.balance, data.balance_currency);
  }
  
  render() {
    const data = this.state.data
    const data_html = []

    if (data) {
        for (var i = 0; i < data.length; i++) {
            const row = data[i]
            const amt = this.getAmount(row)
            const desc = row.comment;
            const date = Helpers.convertDate(row.transaction_date, "D MMM YYYY");
            const balance = this.getBalance(row);
            const textColor = row.amount >= 0 ? 'green' : 'red'
            data_html.push(<tr key={'row-'+ i} style={{color: textColor}}><td>{amt}</td><td>{desc}</td><td>{date}</td><td>{balance}</td></tr>)
        }
    }

    return (     
      <Dialog open={this.state.modal[0]} onClose={this.toggle} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Donations History</DialogTitle>
        <DialogContent>          
          <DialogContentText>
            Overview of all donations received and expenditures.
          </DialogContentText>
          <div>
              <table className='donations-table'>
                <tbody>
                    <tr>
                        <th>Amount</th>
                        <th>Description</th>
                        <th>Date</th>
                        <th>Balance</th>
                    </tr>
                    {data_html}
                </tbody>
              </table>
          </div>     
        </DialogContent>
      </Dialog>)
  }
  
}

export default DonationsPopup;
