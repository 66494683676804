import React, { Component } from 'react';

class Redirect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            status: [0],
            url: [""]
        };

        this.handleRedirect = this.handleRedirect.bind(this);
    }

    handleRedirect() {
        const qs = require('query-string');
        if (this.props.location.search && this.props.location.search.length > 1) {      
          const params = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
          if (params.url && params.url.toLowerCase().startsWith('commandpost://')) {
            window.location.href = params.url;
            //window.open(params.url, '_blank');
            this.state.status[0] = 1;
            this.state.url[0] = decodeURIComponent(params.url);
          } else {
              this.state.status[0] = 2;
          }
        } else {
            this.state.status[0] = 2;
        }
        this.setState({status: this.state.status});
    }

    componentDidMount() {
        this.handleRedirect();
    }

    render() {
        var msg = "<span>Redirecting, please wait.</span>"
        switch (this.state.status[0]) {
            case 1:
                msg = `<span>If redirecting did not work, please click <a href=${this.state.url}>HERE</a>.</span>`;
                break;
            case 2:
                msg = "<span>Invalid redirection.</span>";
                break;
        }

        return (
            <div className='Users w3-margin'>
                <div className='w3-margin' dangerouslySetInnerHTML={{__html: msg}}>
                </div>
             </div>        
        );
    }
}

export default Redirect;