import React from 'react';
import './UnitTreePopup.css';
import { Button, TabPane } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Helpers from '../Helpers';
import { Box, Tab, Grid } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

class UnitTreePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.modal,
      data: this.props.data,
      patch: this.props.patch,      
      err: '',
      myTab: '0',
      showLoading: this.props.loading,
      onItemSelected: this.props.onItemSelected,
    };

    this.toggle = this.toggle.bind(this)
    this.onTabChange = this.onTabChange.bind(this)
  }
    
  componentWillReceiveProps(props) {
    this.setState({modal: props.modal, patch: props.patch, data: props.data, onItemSelected: props.onItemSelected})
  }

  toggle() {
    this.state.modal[0] = !this.state.modal[0];
    this.setState({
      modal: this.state.modal,
    });
  }

  onTabChange(page, newValue) {
    const val = (Math.floor(newValue / 3) == page) ? newValue : false
    this.setState({ myTab: newValue })
  }
  
  render() {
    const data = this.state.data
    const patch = this.state.patch
    const myTab = this.state.myTab
    var factions_html = []

    const isSmall = Helpers.isSmallWindow()

    if (data && patch && data[patch]) {   
      const myData = data[patch]   
      const factionTabsList = []
      const factionTabPanels = []

      var index = -1
      for (var f in myData) {
        index++
        const listIndex = Math.floor(index / 3)

        const factionItem = myData[f]
        const factionName = factionItem.faction
        const factionIcon = factionItem.icon
        if (index % 3 == 0) {
          factionTabsList.push([])
        }

        const categories_html = []   
        if (factionItem.units) {              
          for (var c in factionItem.units) {              
            const unitArr = factionItem.units[c] 

            var count = 0
            var units_html = []
            var row_html = []

            for (var i in unitArr) {
              const unitId = unitArr[i].id
              const unitName = unitArr[i].DisplayName
              const unitImg = unitArr[i].icon
  
              row_html.push(<div key={`tree-factions-${f}-${c}-item-${i}`} className={`col-md-4 col-sm-6 tree-unit-div`} onClick={(e) => this.state.onItemSelected(unitId)}><img src={unitImg} className='tree-unit-img' /><div className='tree-faction-unit-name'>{unitName}</div></div>)
              if ((!isSmall && count % 3 == 2) || (isSmall && count % 2 == 1) || (count == unitArr.length - 1)) {
                units_html.push(<div key={`tree-factions-${f}-${c}-row-${count}`} className='row tree-unit-row-div'>{row_html}</div>)
                row_html = []
              }
              count++
            }
            if (units_html.length > 0) {
              categories_html.push(<div key={`tree-factions-${f}-${c}-div`}><div className='tree-category-title'>{c}</div><div className='tree-units-container'>{units_html}</div></div>)
            }
          }
        }

        factionTabsList[listIndex].push(
          <Tab label={factionName} value={index} />
        )
        factionTabPanels.push(
          <TabPanel value={index}>
            <div key={`tree-factions-${f}`} className='tree-faction-div'>
              <div className='tree-faction-title'>
                <img src={factionIcon} width='30' height='30' />
              </div>
              <div>{categories_html}</div>
            </div>
          </TabPanel>
        )
      }
      factions_html = (
        <TabContext value={myTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={(event, newValue) => this.onTabChange(0, newValue)}>
                  {factionTabsList[0]}
              </TabList>
              <TabList onChange={(event, newValue) => this.onTabChange(1, newValue)}>
                  {factionTabsList[1]}
              </TabList>
              <TabList onChange={(event, newValue) => this.onTabChange(2, newValue)}>
                  {factionTabsList[2]}
              </TabList>              
          </Box>
          {factionTabPanels}
      </TabContext>
      )
    } else {
      factions_html.push( <CircularProgress color="inherit" size={20} />)
    }

    return (     
      <Dialog open={this.state.modal[0]} onClose={this.toggle} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Unit List</DialogTitle>
        <DialogContent>          
          <DialogContentText>
            Select an item from below.
          </DialogContentText>

          <div>
            {factions_html}
          </div>     

        </DialogContent>
        <DialogActions>
          <div>
            <div className='err-text center'>
                {this.state.err}
            </div>
          </div>
        </DialogActions>
      </Dialog>)
  }
  
}

export default UnitTreePopup;

/*
const mapStateToProps = (state) => {
  return {
    stats: state.statsdetails.stats,
    statsTypes: state.statsdetails.statsTypes,      
    detailsLoading: state.statsdetails.loading,    
    trees: state.statsdetails.trees,
    treeLoading: state.statsdetails.treeLoading,
    timestamp: state.statsdetails.timestamp
  }
};

export default connect(mapStateToProps)(StatsSheet);
*/