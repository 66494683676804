import Helpers from '../Helpers.js';

export const FETCH_CONVOS_BEGIN   = 'FETCH_CONVOS_BEGIN';
export const FETCH_CONVOS_SUCCESS = 'FETCH_CONVOS_SUCCESS';
export const FETCH_CONVOS_FAILURE = 'FETCH_CONVOS_FAILURE';
export const UPDATE_CONVO_READ = 'UPDATE_CONVO_READ';

export const fetchConvosBegin = () => ({
  type: FETCH_CONVOS_BEGIN
});

export const fetchConvosSuccess = convos => ({
  type: FETCH_CONVOS_SUCCESS,
  payload: { convos }
});

export const fetchConvosFailure = error => ({
  type: FETCH_CONVOS_FAILURE,
  payload: { error }
});

export const updateConvoRead = convo_id => ({
  type: UPDATE_CONVO_READ,
  payload: { convo_id }
});

export function setConoAsRead(convo_id) {
  return dispatch => {
    dispatch(updateConvoRead(convo_id));
  };
}

export function fetchConvos() {
  return dispatch => {
    dispatch(fetchConvosBegin());
    return fetch(Helpers.getBaseURL() + '/fetch_convos.php', {
      method: 'POST',
      headers: Helpers.addHeaders(),
      body: Helpers.appendParams('type=0')
    })
      .then(res => res.json())
      .then(data => {
        if (data.status == "1" && data.output) {
          dispatch(fetchConvosSuccess(data.output));
          return data.output;
        } else {
          dispatch(fetchConvosFailure(data.message));
        }
      })
      .catch(error => dispatch(fetchConvosFailure(error)));
  };
}
