import React from 'react';
import { Button } from 'reactstrap';
import { Card, CardImg, CardHeader, CardText, CardBody, CardTitle, CardSubtitle, InputGroup, Input, InputGroupAddon } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Helpers from '../Helpers.js';

class ReplayComments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.modal,
      comments: this.props.comments,
      replay_id: this.props.replay_id,
      inputMsg: '',
      disableSend: [true],
      followup_id: '0',
      err: ''
    };

    this.toggle = this.toggle.bind(this);
    this.sendMsg = this.sendMsg.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onReplyClick = this.onReplyClick.bind(this);
  }

  componentWillReceiveProps(props) {
    this.state.modal = this.props.modal;
    this.state.comments = this.props.comments;
    this.state.replay_id = this.props.replay_id;
    this.setState({modal: this.state.modal, replay_id: this.state.replay_id, comments: this.state.comments});
    this.forceUpdate();
  }

  toggle() {
    this.state.modal[0] = !this.state.modal[0];
    this.setState({
      modal: this.state.modal[0]
    });
  }

  handleChange(event) {
    const msg = document.getElementById('msgInput').value.trim();
    if (msg && msg.length > 2 && this.state.followup_id) {
      this.state.disableSend[0] = false;
    } else {
      this.state.disableSend[0] = true;
    }
    this.setState({inputMsg: msg, disableSend: this.state.disableSend});
  }

  onReplyClick(i) {
    this.setState({followup_id: this.state.comments[i].id});
  }

  sendMsg() {
    if (!this.state.inputMsg || this.state.inputMsg.length == 0)
      return;

    fetch(Helpers.getBaseURL() + '/fetch_replays_comments.php', {
      method: 'POST',
      headers: Helpers.addHeaders(),
      body: Helpers.appendParams('replay_id=' + this.state.replay_id[0] + '&type=1&followup_id=' + this.state.followup_id + '&comment=' + this.state.inputMsg)
    })
    .then(response => {
      var stuff = response.json();
      return stuff;
    })
    .then(data => {

      if (data.status == '1') {
        document.getElementById('msgInput').value = '';
        this.handleChange(null);

        this.setState({comments: data.output, err: ''});
      } else {
        this.setState({'err': data.message});
      }

      this.setState({showLoading: [false]});
      this.forceUpdate();

    })
    .catch(function(error) {
      this.setState({err: error, showLoading: [false]});
      this.forceUpdate();
    }.bind(this));
  }

  render() {

    const stuff = [];
    const canRender = this.state.comments && this.state.comments.length > 0;
    if (canRender) {
      for (var i = 0; i < this.state.comments.length; i++) {
        const curPos = i;
        const avatar = Helpers.loadAvatar(this.state.comments[i].user_avatar);
        stuff.push(
          <Card key={'card-'+i}>
            <CardHeader>
              <div className='row'>
                <div className='col-2'>
                  <img className='avatar-small' src={avatar} />
                </div>
                <div className='col-8'>
                  {this.state.comments[i].user_name}
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <div className='center text-small'>
                {this.state.comments[i].created_at}
              </div>
              <div className='row container'>
                <div className='col-10'>
                  {this.state.comments[i].comment}
                </div>
                <div className='col-2 align-self-center'>
                  <Button style={{background: '#1976D2'}} onClick={() => this.onReplyClick(curPos)}>Reply</Button>
                </div>
              </div>
            </CardBody>
          </Card>
        );
      }
    }

    return (
      <div>
        <Dialog open={this.state.modal[0]} onClose={this.toggle} className={this.props.className} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title"><b>Replay Comments: {canRender && '#' + this.state.replay_id[0]}</b></DialogTitle>
            <DialogContent>
              <div>
                {stuff}
              </div>
              <div className='flex-container'>
                <input className='flex-4 input' id='msgInput' onChange={this.handleChange} />
                <Button className='flex-1' type='submit' disabled={this.state.disableSend[0]} onClick={this.sendMsg}>Send</Button>
              </div>
              <div className='err-text center' style={{'margin-top': '15px'}}>
                {this.state.err}
              </div>
            </DialogContent>
            <DialogActions>
              <div>
                <Button color="primary" onClick={this.toggle}>OK</Button>
              </div>
            </DialogActions>
          </Dialog>  
      </div>
    );
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
   // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
   if (event.key === 'Enter') {
     event.preventDefault();
     event.stopPropagation();
     this.sendMsg();
   }
 }

}

export default ReplayComments;
