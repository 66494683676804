import Helpers from '../Helpers.js';

export const FETCH_METADATA_BEGIN   = 'FETCH_METADATA_BEGIN';
export const FETCH_METADATA_SUCCESS = 'FETCH_METADATA_SUCCESS';
export const FETCH_METADATA_FAILURE = 'FETCH_METADATA_FAILURE';

export const fetchMetadataBegin = () => ({
  type: FETCH_METADATA_BEGIN
});

export const fetchMetadataSuccess = metadata => ({
  type: FETCH_METADATA_SUCCESS,
  payload: { metadata }
});

export const fetchMetadataFailure = error => ({
  type: FETCH_METADATA_FAILURE,
  payload: { error }
});


export function fetchMetadata() {
  return dispatch => {
    dispatch(fetchMetadataBegin());
    return fetch(Helpers.getBaseURL() + '/fetch_metadata.php', {
      method: 'POST',
      headers: Helpers.addHeaders(),
      body: Helpers.appendParams('type=0')
    })
      .then(res => res.json())
      .then(data => {
        if (data.status == "1" && data.output) {
          dispatch(fetchMetadataSuccess(data.output));
          return data.output;
        } else {
          dispatch(fetchMetadataFailure(data.message));
        }
      })
      .catch(error => dispatch(fetchMetadataFailure(error.message)));
  };
}
