import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import Helpers from '../Helpers.js';
import './SearchHeader.css';

class SearchHeader extends Component {

  constructor(props) {
    super(props);

    this.onGotoKeyPressed = this.onGotoKeyPressed.bind(this);
    this.onSearchKeyPressed = this.onSearchKeyPressed.bind(this);
  }

  onSearchKeyPressed(e) {
    if (e.charCode == 13) {
      this.props.onSearchClicked();
    }
  }

  onGotoKeyPressed(e) {
    if(e.charCode == 13){
      this.props.onPageGotoClicked();
    }
  }

  render() {
    const numShownItems = this.props.total_count[0] > this.props.num_per_page[0] ? this.props.num_per_page[0] : this.props.total_count[0];

    return (
      <div className='SearchHeader' style={{'margin': '10px'}}>
        <div className='col-md-6 col-lg-3'>
          <div className='search-header-search-input float'>
              <Input value={this.props.search_text} onChange={this.props.onSearchChange} onKeyPress={this.onSearchKeyPressed} />
          </div>
          <Button style={{'marginRight': '15px'}} className='search-header-btn float' onClick={this.props.onSearchClicked}>Search</Button>
        </div>
        <div className='col-md-6 col-lg-9'>
          <Button className='search-header-btn float' onClick={this.props.onPrevClicked}>&lt;</Button>
          <div className='search-header-page-input float'>
            <Input placeholder={this.props.page_cur[0]} readOnly />
          </div>
          <Button className='search-header-btn float' onClick={this.props.onNextClicked}>&gt;</Button>
          <div className='search-header-page-input float'>
            <Input value={this.props.page_goto[0]} onChange={this.props.onPageGotoChange} onKeyPress={this.onGotoKeyPressed} />
          </div>
          <Button className='search-header-btn float' onClick={this.props.onPageGotoClicked}>GO</Button>
        </div>

        <div className='search-header-max-page float'>{this.props.page_max[0]} Pages</div>
        <div className='search-header-total-count float'>Showing {numShownItems} of {this.props.total_count[0]} Results</div>
      </div>
    );
  }

}

export default SearchHeader;
