import React, { Component } from 'react';
import './LadderDetails.css';
import Helpers from '../Helpers.js';


class LadderDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      onUserClick: this.props.onUserClick
    };
  }

  componentWillReceiveProps(props) {
    this.state.user = this.props.user;
    this.setState({user: this.state.user});
    this.forceUpdate();
  }

  render() {

    const avatar = Helpers.loadAvatar(this.state.user.user_avatar);
    const record = this.state.user.wins + "-" + this.state.user.losses;
    const faction = Helpers.getFactionIcon(this.state.user.faction);

    return (
      <div className='LadderDetails row' onClick={() => this.state.onUserClick(this.state.user)}>
        <div className='col-sm-3 col-md-2'>
          <div className='ladder-details-num-div'>
             <div>Rank</div>
             <span className='ladder-details-num'>#{this.state.user.num}</span>            
          </div>
        </div>
        <div className='col-sm-6 col-md-5'>
          <div className='ladder-details-username-div'>
          <img className='ladder-details-avatar-img' src={avatar} />
            <span>
              {this.state.user.user_name}
            </span>
          </div>
          <div>
            <span className='ladder-details-pts'>{this.state.user.pts} Pts</span>
          </div>
        </div>
        <div className='col-sm-3 col-md-5'>
          <img className='ladder-details-faction-img' src={faction} />
          <div>Record</div>
          <span className='ladder-details-pts'>{record}</span>
        </div>
      </div>
    );
  }

}


export default LadderDetails;
