import React, { Component } from 'react';
import './Home.css';
import { Card, CardImg, CardHeader, CardText, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap';
import Helpers from '../Helpers.js';
import Popup from '../components/Popup.js';
import { Progress } from 'reactstrap';

const NewsItem = (props) => {

  const curDate = Helpers.convertDate(props.obj.date_created);

  var urlTarget = "_blank";
  var url = props.obj.action;
  var newUrl = Helpers.convertUrl(url)
  if (newUrl != url) {
    url = newUrl;
    urlTarget = "_self";
  }

  const urlTarget1 = urlTarget;

  const btn = props.obj.button && props.obj.action ? (<a href={url} target={urlTarget1}><Button color='primary' style={{'marginTop': '15px'}}>{props.obj.button}</Button></a>) : (<div/>);
  const caption = props.obj.caption ? (<span style={{'backgroundColor': 'red', 'color': 'white', 'paddingRight': '5px', 'paddingLeft': '5px', 'marginRight': '5px'}}>{props.obj.caption}</span>) : (<div/>);
  const imgUrl = Helpers.loadBanner(props.obj.img);

  const summary = 
  (
    <span className='text-pre' dangerouslySetInnerHTML={{__html: Helpers.linkifyText(props.obj.summary)}} />          
  );

  return (
    <Card style={{'marginBottom': '5px'}}>
      <CardHeader>
        <div className='row'>
          <div className='col-lg-6 col-xs-12'>
            {caption}
            {props.obj.title}
          </div>
          <div className='col-lg-6 col-xs-12'>
            {curDate}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className='row'>
          <div className='container col-lg-2 col-xs-12'>
            <img src={imgUrl} className='news-img' />
          </div>
          <div className='col-lg-10 col-xs-12'>
            <div>              
              {summary}
            </div>
            {btn}
          </div>
        </div>
      </CardBody>
    </Card>

  );
};

class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      chat: [],
      showLoading: [false],
      err: ['']
    };

    this.getNews = this.getNews.bind(this);
  }

  componentDidMount() {
    this.getNews();
  }

  getNews() {
    this.state.showLoading[0] = true;
    this.setState({showLoading: this.state.showLoading});

    fetch(Helpers.getBaseURL() + '/fetch_news.php', {
      method: 'POST',
      headers: Helpers.addHeaders(),
      body: Helpers.appendParams('type=0')
    })
    .then(response => {
      var stuff = response.json();
      return stuff;
    })
    .then(data => {

      if (data.status == '1') {
        this.state.chat = data.output;
        this.state.err[0] = '';
      } else {
        this.state.err[0] = data.message;
      }

      this.state.showLoading[0] = false;

      this.setState({chat: this.state.chat, showLoading: this.state.showLoading, err: this.state.err});

    })
    .catch(function(error) {
      this.state.showLoading[0] = false;
      this.state.err[0] = error.message;
      this.setState({showLoading: this.state.showLoading, err: this.state.err});
    }.bind(this));
  }

  render() {
    const isLoading = this.state.showLoading[0];
    const stuff = [];
    var numActiveUsers = 0;
    if (!isLoading)
    {
      if (this.state.chat && this.state.chat.length > 0) {
        for (var i = 0; i < this.state.chat.length; i++) {
          const curPos = i;
          const chatObj = this.state.chat[curPos];
          const uid = 'chatmsg-' + curPos;
          stuff.push(
            <div key={uid}>
              <NewsItem obj={chatObj} />
            </div>
          );
        }

        return (
          <div className='w3-padding app-bg'>
            <div className='w3-margin'>
              <div>
                {stuff}
              </div>
            </div>
          </div>
        );
      } else {
        if (Helpers.isLoggedIn()) {
          return (
            <div className='Users w3-margin'>
                {this.state.err[0]}
            </div>
          );
        } else {
          return (
            <div className='Users w3-margin'>
              <div className='w3-margin'>
               You must be logged in to view this.
              </div>
            </div>
          );
        }
      }
    } else {
      return (<div className='w3-margin'><Progress bar animated value="100">Loading</Progress></div>);
    }
  }

}


export default Home;
