import {
  FETCH_DONATIONS_BEGIN,
  FETCH_DONATIONS_SUCCESS,
  FETCH_DONATIONS_FAILURE,
  UPDATE_CONVO_READ,
} from '../redux-actions/donations.js';

const initialState = {
  donations: [],
  loading: false,
  error: null,
  isLoaded: false,
  timestamp: 0
};

export default function donationsReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_DONATIONS_BEGIN:

      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_DONATIONS_SUCCESS:
      const a = {
        ...state,
        loading: false,
        donations: action.payload.donations,
        isLoaded: true,
        timestamp: new Date().getTime()
      };
      return a;

    case FETCH_DONATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        donations: [],
        isLoaded: false,
        timestamp: new Date().getTime()
      };

    default:
      return state;
  }
}