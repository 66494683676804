import React, { Component } from 'react';
import './Subscribe.css'
import { connect } from "react-redux";
import { reportDonations } from "../redux-actions/donations.js";
import Helpers from '../Helpers.js';
import PaypalSubscribeButton from '../components/PaypalSubscribeButton';
import PaypalDonateButton from '../components/PaypalDonateButton';

class Subscribe extends Component {

    constructor(props) {
        super(props);
        this.onDonateClick = this.onDonateClick.bind(this);
    }

    componentDidMount () {

    }

    onDonateClick() {
    window.location.href = "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=PWMFFZTHYPFN4&source=url";
    }

    render() {
        return (
            <div className='subscribe-main-div'>
                <h2>Why Subscribe?</h2>
                <div className='subscribe-intro-div'>
                    <div>
                        Command Post/KW-UPLOADS incurs the following costs:
                    </div>
                    <ul>
                        <li><span className='wt-500'>Server/Hosting Charges:</span> ~$100 / Month</li>
                        <li><span className='wt-500'>Tournaments/Events:</span> Financed by donations and contributors. These transactions can be viewed by clicking on 
                            'How is it spent?' on the above Donations bar.</li>
                    </ul>                        
                    <div>
                        The KW community is composed of several talented players and content creators, each of whom have been extremely
                        valuable to evolving the game and showcasing it to others. Command Post supports our awesome community by hosting
                        all kinds of game content, events and quality of life features. However, there are bills to pay in exchange for
                        keeping these services up and running smoothly.                        
                    </div>
                    <br />
                    <div>
                        If you like what you see on Command Post, please consider supporting it and the KW community by signing up 
                        for a monthly subscription to be a Premium Member! There are 3 tiers of Premium membership, with fees as low as $1 a month.
                        Subscription fees will be exclusively used to pay off Hosting charges, while surpluses will
                        be used to host more events = more KW content for everyone to enjoy! Plus, you enjoy a few additional benefits as
                        a Command Post user.
                    </div>
                </div>
                <br />
                <h2>Choose your rank, Commander!</h2>
                <br />
                <div className='row'>
                    <div className='subscribe-types-div col-lg-4 col-md-12'>
                        <div className='subscribe-card-div'>
                            <div>
                                <h3><img src={require('../res/icon_veteran.png')} className='subscribe-icon' />Veteran @ $1/Month<img src={require('../res/paypal.png')} className='paypal-icon' /></h3>
                                <ul>
                                    <li>'Veteran' badge</li>
                                    <li>Increased Match History replay storage</li>
                                </ul>
                            </div>
                            <PaypalDonateButton text='Subcribe!' url='https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-0V2508379G9006211MRS6OBA' />
                        </div>
                    </div>
                    <div className='subscribe-types-div col-lg-4 col-md-12'>
                        <div className='subscribe-card-div'>
                            <div>
                                <h3><img src={require('../res/icon_elite.png')} className='subscribe-icon' />Elite @ $3/Month<img src={require('../res/paypal.png')} className='paypal-icon' /></h3>         
                                <ul>
                                    <li>'Elite' badge</li>                                
                                    <li>Unlimited Match History replay storage</li>
                                    <li>Custom Event Hosting</li>
                                    <li>Access to new Beta features</li>
                                </ul>                                               
                            </div>
                            <PaypalDonateButton text='Subcribe!' url='https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-4LA39761BC3737151MRS6OJI' />                            
                        </div>
                    </div>
                    <div className='subscribe-types-div col-lg-4 col-md-12'>
                        <div className='subscribe-card-div'>
                            <div>
                                <h3><img src={require('../res/icon_heroic.png')} className='subscribe-icon' />Heroic @ $10/Month<img src={require('../res/paypal.png')} className='paypal-icon' /></h3>                            
                                <ul>
                                    <li>'Heroic' badge</li>
                                    <li>Unlimited Match History replay storage</li>
                                    <li>Custom Event Hosting</li>
                                    <li>High-Priority Feature Requests</li>
                                    <li>Access to new Beta features</li>
                                </ul>
                            </div>
                            <PaypalDonateButton text='Subcribe!' url='https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-1MC81174NB003513NMRS6QZI' />
                        </div>
                    </div>
                </div>
                <div className='subscribe-custom-div'>
                    <h3>OR make a custom/one-time donation via Paypal.<img src={require('../res/paypal.png')} className='paypal-icon' /></h3>                    
                    <PaypalDonateButton />
                </div>
                <div className='subscribe-custom-div'>
                    <h3>OR become a Patron!<img src={require('../res/patreon.png')} className='paypal-icon' /></h3>
                    <PaypalDonateButton url='https://www.patreon.com/CommandPost' text='Subscribe @ Patreon' />
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
});

export default connect(mapStateToProps)(Subscribe);