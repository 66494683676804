import React, { Component } from 'react';
import './Tournaments.css';
import { Card, CardImg, CardHeader, CardText, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap';
import Helpers from '../Helpers.js';
import { Progress } from 'reactstrap';
import { Redirect } from 'react-router';

const TournamentItem = (props) => {

    const curDate = "Event Date: "+ Helpers.convertDate(props.obj.date_event);
  
    const imgUrl = Helpers.loadBanner(props.obj.img);
    const btn = (<Button color='primary' style={{'marginTop': '15px'}} onClick={props.onActionClick}>Details</Button>);
  
    const summary = Helpers.linkifyText(props.obj.summary);
    var statusStyle = 'tournament-status-inactive';
    var statusTxt = 'Inactive';
    var activePlayerStyle = 'none';
    switch(props.obj.status) {
        case 4:
            statusStyle = 'tournament-status-completed';
            statusTxt = 'Finished';
            activePlayerStyle = 'block';
            break;
        case 3:
            statusStyle = 'tournament-status-playing';
            statusTxt = 'In Progress';
            activePlayerStyle = 'block';
            break;
        case 2:
            statusStyle = 'tournament-status-cancelled';
            statusTxt = 'Cancelled';
            break;
        case 1:
            statusStyle = 'tournament-status-active';
            statusTxt = 'Active';
            break;
    }

    var totalPlayerCount = 0;
    var activePlayerCount = 0;
    for (var i = 0; i < props.obj.players.length; i++) {
        const player = props.obj.players[i];
        if (player.status > 0) {
            activePlayerCount++;
        }
        totalPlayerCount++;
    }

    return (
      <Card className='Tournament'>
        <CardHeader>
          <div className='row'>
            <div className='col-lg-6 col-xs-12'>
              {props.obj.name}
            </div>
            <div className='col-lg-2 col-xs-12'>
                <div className={statusStyle}>
                {statusTxt}
                </div>
            </div>  
            <div className='col-lg-6 col-xs-12'>
              {curDate}
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div>
            <img src={imgUrl} className='img-tournament-banner' />
            <div className='div-tournament-players'>
                <div>
                    Total Sign-Ups: <span className='wt-500'>{totalPlayerCount}</span>
                </div>
                <div style={{'display': {activePlayerStyle}}}>
                    Checked-In: <span className='wt-500'>{activePlayerCount}</span>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-10 col-xs-12'>
                    <div className='div-tournament-summary'>
                        {summary}
                    </div>
                    {btn}
                </div>
            </div>
          </div>
        </CardBody>
      </Card>
  
    );
};

class Tournaments extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tournaments: [],
            err: '',
            tournamentObj: null,
            showLoading: [false]                
        };

        this.fetchTournaments = this.fetchTournaments.bind(this);
        this.onActionClick = this.onActionClick.bind(this);
    }

    componentDidMount() {
        this.fetchTournaments();
    }

    fetchTournaments() {
        this.state.showLoading[0] = true;
        this.setState({'showLoading': this.state.showLoading});

        fetch(Helpers.getBaseURL() + '/fetch_tournaments.php', {
            method: 'POST',
            headers: Helpers.addHeaders(),
            body: Helpers.appendParams()
          })
          .then(response => {
            var stuff = response.json();
            return stuff;
          })
          .then(data => {

            if (data.status == '1') {
                this.state.err = '';
            } else {
                this.state.err = data.message;
            }
      
            this.state.showLoading[0] = false;
      
            this.setState({tournaments: data.output, err: this.state.err, showLoading: this.state.showLoading});
          })
          .catch(function(error) {
            this.state.showLoading[0] = false;
            this.setState({err: error.message, showLoading: this.state.showLoading});
          }.bind(this));
    }

    onActionClick(tournament) {
        this.props.history.push('/tournaments/' + tournament.id);
    }
    
    render() {
        const isLoading = this.state.showLoading[0];
        const stuff = [];
        if (!isLoading)
        {
            if (this.state.tournamentObj) {
                const tournament_id = this.state.tournamentObj.id;
                return <Redirect push to={"/tournaments/" + {tournament_id}} />;
            }

            if (this.state.tournaments && this.state.tournaments.length > 0) {
                for (var i = 0; i < this.state.tournaments.length; i++) {
                    const curPos = i;
                    const tournamentObj = this.state.tournaments[curPos];
                    const uid = 'tournamentItem-' + curPos;
                    stuff.push(
                    <div key={uid}>
                        <TournamentItem onActionClick={() => this.onActionClick(tournamentObj)} obj={tournamentObj} />
                    </div>
                    );
                }
            }
        } else {
            return (
                <div className='w3-margin'>
                    <Progress bar animated value="100">Loading</Progress>
                </div>                    
            );
        }

        return (
            <div className='w3-margin'>
                <div>
                    {stuff}
                </div>
                <div className='err-text-large'>{this.state.err}</div>      
            </div>        
        );
    }
}

export default Tournaments;