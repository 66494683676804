import React from 'react';
import { Button } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class Popup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: props.modal,
      title: props.title,
      text: props.text,
      url: props.url,
    };

    this.toggle = this.toggle.bind(this);
    this.onClickBtn = this.onClickBtn.bind(this);
  }

  componentWillReceiveProps(props) {
    this.state.modal = props.modal;
    this.state.title = props.title;
    this.state.text = props.text;
    this.state.url = props.url;
    this.setState({modal: this.state.modal, title: this.state.title, text: this.state.text, url: this.state.url});
  }

  toggle() {
    this.state.modal[0] = !this.state.modal[0];
    this.setState({
      modal: this.state.modal
    });
  }

  onClickBtn() {
    this.toggle();
  }

  render() {
    const canRender = this.state.text.length > 0;
    // TODO: HTTPS DOWNLOADS DO NOT WORK DUE TO ROUTING!!!
    const url = this.state.url// ? this.state.url.replace("https://", "http://") : null;

    const okBtn = url ? (
      <a href={url} target="_blank">
        <Button color="primary" onClick={this.onClickBtn}>OK</Button>
      </a>
    ) : (
        <Button color="primary" onClick={this.onClickBtn}>OK</Button>
    );

    if (canRender) {
      return (
        <div>
          <Dialog open={this.state.modal[0]} onClose={this.toggle} className={this.props.className} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title"><b>{this.state.title}</b></DialogTitle>
            <DialogContent>
              <div className='text-pre' dangerouslySetInnerHTML={{__html: this.state.text}}>
              </div>
            </DialogContent>
            <DialogActions>
              <div>
                {okBtn}
              </div>
            </DialogActions>
          </Dialog>          
        </div>
      );
    } else {
      return (
        <div>
        </div>
      );
    }
  }
}

export default Popup;
