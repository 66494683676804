import Helpers from './Helpers.js';
import { getMessaging, getToken } from "firebase/messaging";
import { vapidKey } from './firebaseConfig.js';

export function initializePush(onError) {

  function sendTokenToServer(token) {
    fetch(Helpers.getBaseURL() + '/fcm_push.php', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: Helpers.appendParams('type=1&fcm_token=' + token)
    })
    .then(response => response.json())
    .then(data => {
      console.log("Server response:", data.message);
      //onError(data.message)
    })
    .catch(error => {
      console.log("Error sending token to server:", error);
    });
  }

  function getFcmToken() {
    try {
      const messaging = getMessaging();

      getToken(messaging, {vapidKey})
      .then(token => {
        if (token) {
          sendTokenToServer(token);
        } else {
          onError("Invalid FCM token.")
        }
      })
      .catch(error => {
          console.log("Failed to enable notifications: ", error)
          onError("Failed to enable notifications: ", error.code)
      });
    } catch (error) {
      console.log(error)
      onError("Error occurred while initializing notifications:", error)
    }    
  }
  
  if ('Notification' in window) {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        getFcmToken();
      } else {
        console.log("Please enable notifications manually.");
        onError("Please enable notifications manually.");
      }
    });
  } else {
    console.log("Notifications are not supported on this device/browser.");
    onError("Notifications are not supported on this device/browser.");
  }  
    
 }