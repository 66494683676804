import React, { Component } from 'react';
import './Users.css';
import './Ladders.css';
import LadderDetails from '../components/LadderDetails.js';
import UserDetailsPopup from '../components/UserDetailsPopup';
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu} from 'reactstrap';
import Helpers from '../Helpers.js';
import { Progress } from 'reactstrap';

class Ladders extends Component {

  constructor(props) {
    super(props);

    this.state = {
      users: [],
      ladderTypes: [],
      selectedLadder: null,
      showLoading: [false],
      err: [''],
      showUserDetailsPopup: [false],
      selectedUser: null,
      dropdownOpen: false
    };

    this.onUserClick = this.onUserClick.bind(this);
    this.fetchLadders = this.fetchLadders.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  componentDidMount() {
    this.fetchLadders(0);
  }

  fetchLadders(ladderType) {
    this.state.showLoading[0] = true;
    this.setState({showLoading: this.state.showLoading});

    fetch(Helpers.getBaseURL() + '/fetch_ladders.php', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: Helpers.appendParams('ladder_type=' + ladderType)
    })
    .then(response => response.json())
    .then(data => {
      this.state.showLoading[0] = false;

      if (data.status == '1') {
        this.state.users = data.output;
        this.state.ladderTypes = data.sort_filters;
        this.state.selectedLadder = data.sort_filters[ladderType];
        this.state.err[0] = '';
      } else {
        this.state.err[0] = data.message;
      }

      this.setState({users: this.state.users, ladderTypes: this.state.ladderTypes, selectedLadder: this.state.selectedLadder, showLoading: this.state.showLoading, err: this.state.err});
      //this.forceUpdate();
    })
    .catch(function(error) {
      this.state.showLoading[0] = false;
      this.state.err[0] = error;
      this.setState({showLoading: this.state.showLoading, err: this.state.err});
    }.bind(this));
  }

  render() {
    const isLoading = this.state.showLoading[0];
    let stuff = [];
    let numActiveUsers = 0;
    if (!isLoading)
    {
      if (this.state.users) {
        for (var i = 0; i < this.state.users.length; i++) {
          const curPos = i;
          const user = this.state.users[curPos];
          const uid = 'ladderdetails-' + curPos;
          numActiveUsers++;
          stuff.push(
            <LadderDetails key={uid} user={user} onUserClick={this.onUserClick} />
          );
        }

        if (stuff.length == 0) {
          stuff = (<span className=''>No active users found on this ladder! Please check another ladder.</span>);
        }

        var dropdownList = [];
        for (var i = 0; i < this.state.ladderTypes.length; i++) {
          const pos = i;
          const uid = 'ladderdetails-dropdown-' + i;
          dropdownList.push(
            <DropdownItem key={uid} onClick={() => this.fetchLadders(pos)}>{this.state.ladderTypes[pos]}</DropdownItem>
          );
        }

        return (
          <div>
            <div className='Users w3-margin'>
              <div>
                To look up more user information, check the Ladders tab on <a href={Helpers.CP_URL}>Command Post</a>.
              </div>
              <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown} style={{'margin': '20px 0px 15px 0px'}}>
                <DropdownToggle caret>
                  {this.state.selectedLadder}
                </DropdownToggle>
                <DropdownMenu>
                  {dropdownList}
                </DropdownMenu>
              </Dropdown>
              {numActiveUsers > 0 ? (
                <div>
                  Showing {numActiveUsers} user(s)
                </div> ) : null
              }
              <div className='ladders-content-div'>
                {stuff}
              </div>
            </div>
            <UserDetailsPopup modal={this.state.showUserDetailsPopup} user={this.state.selectedUser} />
          </div>
        );
      } else {
        if (Helpers.isLoggedIn()) {
          return (
            <div className='Users w3-margin'>
                {this.state.err[0]}
            </div>
          );
        } else {
          return (
            <div className='Users w3-margin'>
              <div className='w3-margin'>
               You must be logged in to view this.
              </div>
            </div>
          );
        }
      }
    } else {
      return (<div className='w3-margin'><Progress bar animated value="100">Loading</Progress></div>);
    }
  }

  toggleDropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  onUserClick(user) {
    /*
    this.state.showUserDetailsPopup[0] = true;
    this.setState({selectedUser: user, showUserDetailsPopup: this.state.showUserDetailsPopup});
    */
  }

}


export default Ladders;
