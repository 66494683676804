import Helpers from '../Helpers.js';

export const FETCH_TRANSACTIONS_BEGIN   = 'FETCH_TRANSACTIONS_BEGIN';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_FAILURE = 'FETCH_TRANSACTIONS_FAILURE';

export const fetchTransactionsBegin = () => ({
  type: FETCH_TRANSACTIONS_BEGIN
});

export const fetchTransactionsSuccess = transactions => ({
  type: FETCH_TRANSACTIONS_SUCCESS,
  payload: { transactions }
});

export const fetchTransactionsFailure = error => ({
  type: FETCH_TRANSACTIONS_FAILURE,
  payload: { error }
});

export function fetchTransactions() {
  return dispatch => {
    dispatch(fetchTransactionsBegin());
    return fetch(Helpers.getBaseURL() + '/fetch_transactions.php', {
      method: 'POST',
      headers: Helpers.addHeaders(),
      body: Helpers.appendParams('type=0')
    })
      .then(res => res.json())
      .then(data => {
        if (data.status == "1" && data.output) {
          dispatch(fetchTransactionsSuccess(data.output));
          return data.output;
        } else {
          dispatch(fetchTransactionsFailure(data.message));
        }
      })
      .catch(error => dispatch(fetchTransactionsFailure(error)));
  };
}
