import React, { Component } from 'react';
import './UserDetailsPopup.css';
import { Button } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Helpers from '../Helpers.js';


class UserDetailsPopup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: [false],
      user: this.props.user,
      player: this.props.player,
      refTime: this.props.refTime
    };

    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps(props) {
    this.state.modal = props.modal;
    this.state.user = props.user;
    this.state.player = props.player;
    this.setState({modal: this.state.modal, user: this.state.user});
    this.forceUpdate();
  }

  toggle() {
    this.state.modal[0] = !this.state.modal[0];
    this.setState({
      modal: this.state.modal[0]
    });
  }

  render() {

    if (this.state.user) {
      const avatar = Helpers.loadAvatar(this.state.user.avatar);
      const ip_addr = this.state.user.ip_address;
      const mac_id = this.state.user.machine_id;

      const lastLoginObj = Helpers.activityTimeToStr(this.state.user.last_login, this.state.refTime[0]);

      let lastLoginStr;
      let lastLoginStyle;
      if (lastLoginObj.is_valid == 1 && lastLoginObj.seconds < 75) {
        if (this.state.user.game_status == '0') {
          lastLoginStr = "Online";
          lastLoginStyle = {'color': 'green'};
        } else if (this.state.user.game_status == '2') {
          lastLoginStr = "Online/In-Game";
          lastLoginStyle = {'color': 'darkgreen'};
        } else {
          lastLoginStr = "Online/Game Alt-Tabbed";
          lastLoginStyle = {'color': 'darkgreen'};
        }
      } else {
        lastLoginStr = "Offline";
        lastLoginStyle = {'color': 'black'};
      }

      const regDate = Helpers.convertDate(this.state.user.date_created);
      let userType;
      switch (this.state.user.status) {
        case '0':
          userType = 'Regular User';
          break;
        case '1':
          userType = 'Super User';
          break;
        case '5':
          userType = 'Banned';
          break;
        case '100':
          userType = 'Super Admin';
          break;
      }

      const lastActiveObj = Helpers.activityTimeToStr(this.state.user.last_activity, this.state.refTime[0]);
      const lastActiveStr = lastActiveObj.str;

      return (
        <div>
          <Dialog open={this.state.modal[0]} onClose={this.toggle} className={this.props.className} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title"><b>{this.state.user.name}</b></DialogTitle>
            <DialogContent>
            <div className='user-details-popup-modalbody'>
                <img className='avatar-large' src={avatar} style={{'marginBottom': '10px'}} />
                <div>
                  <div className="flex-container">
                    <span className='user-details-popup-key'>Status</span>
                    <span className='user-details-popup-val' style={lastLoginStyle}>{lastLoginStr}</span>
                  </div>
                  <div className="flex-container">
                    <span className='user-details-popup-key'>Last Active</span>
                    <span className='user-details-popup-val' style={lastLoginStyle}>{lastActiveStr}</span>
                  </div>
                  <div className="flex-container">
                    <span className='user-details-popup-key'>Registered</span>
                    <span className='user-details-popup-val'>{regDate}</span>
                  </div>
                  <div className="flex-container">
                    <span className='user-details-popup-key'>User Type</span>
                    <span className='user-details-popup-val'>{userType}</span>
                  </div>
                  <div className="flex-container">
                    <span className='user-details-popup-key'>About</span>
                    <span className='user-details-popup-val'>{this.state.user.about}</span>
                  </div>
                  {ip_addr ? (
                    <div className="flex-container">
                      <span className='user-details-popup-key'>IP Address</span>
                      <span className='user-details-popup-val'>{this.state.user.ip_address}</span>
                    </div>
                    ) : (<div/>)
                  }
                  {mac_id ? (
                    <div className="flex-container">
                      <span className='user-details-popup-key'>MAC ID</span>
                      <span className='user-details-popup-val'>{this.state.user.machine_id}</span>
                    </div>
                    ) : (<div/>)
                  }
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <div>
                <Button color="primary" onClick={this.toggle}>OK</Button>
              </div>
            </DialogActions>
          </Dialog>   
        </div>
      );
    } else if (this.state.player) {

      const id = this.state.player.id + 1;
      const faction = Helpers.getFactionName(this.state.player.faction, this.state.player.rand_faction);
      const ip = Helpers.parseIPAddress(this.state.player.ip_address);
      const team = this.state.player.team + 1;
      const color = Helpers.getPlayerColor(this.state.player.color);

      const is_ai = this.state.player.is_ai;
      const ai_type = Helpers.getAIType(this.state.player.ai_type);

      return (
        <div>
          <Dialog open={this.state.modal[0]} onClose={this.toggle} className={this.props.className} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title"><b>{this.state.player.name}</b></DialogTitle>
            <DialogContent>
            <div className='user-details-popup-modalbody'>
                <div>
                  <div className="flex-container">
                    <span className='user-details-popup-key'>ID</span>
                    <span className='user-details-popup-val'>{id}</span>
                  </div>
                  <div className="flex-container">
                    <span className='user-details-popup-key'>Faction</span>
                    <span className='user-details-popup-val'>{faction}</span>
                  </div>
                  {is_ai ? (
                  <div className="flex-container">
                    <span className='user-details-popup-key'>AI Type</span>
                    <span className='user-details-popup-val'>{ai_type}</span>
                  </div> ) : (
                  <div className="flex-container">
                    <span className='user-details-popup-key'>IP Address</span>
                    <span className='user-details-popup-val'>{ip}</span>
                  </div>
                  )}
                  <div className="flex-container">
                    <span className='user-details-popup-key'>Team</span>
                    <span className='user-details-popup-val'>{team}</span>
                  </div>
                  <div className="flex-container">
                    <span className='user-details-popup-key'>Color</span>
                    <span className='user-details-popup-val'>{color}</span>
                  </div>       
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <div>
                <Button color="primary" onClick={this.toggle}>OK</Button>
              </div>
            </DialogActions>
          </Dialog>   
        </div>
      );
    } else {
        return null;
    }
  }
}

export default UserDetailsPopup;
