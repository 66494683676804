import React from 'react';
import { NavLink } from 'react-router-dom';
import './Footer.css';
import { version } from '../../package.json';

class Footer extends React.Component {
    constructor(props) {
      super(props);
    
        this.onVersionClick = this.onVersionClick.bind(this)
    }

    onVersionClick() {
        window.location.reload(true)
    }

    render() {
        const curYear = new Date().getFullYear();
        const ver = 'v' + version

        return (
            <div className='Footer row'>
                <div className='footer-content-left col-md-6 col-sm-12'>
                    <span className='version-text' onClick={this.onVersionClick}>
                        {ver}
                    </span>
                </div>
                <div className='footer-content-right col-md-6 col-sm-12'>
                    <span>
                        Copyright KW-UPLOADS @ 2017-{curYear}                        
                    </span>
                    <span style={{'textAlign': 'right', 'marginLeft': '20px'}}>
                        <NavLink to='/privacy-policy'>Privacy Policy</NavLink>
                    </span>
                </div>
            </div>
        );     
    }

}

export default Footer;