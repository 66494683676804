import {
  FETCH_CONVOS_BEGIN,
  FETCH_CONVOS_SUCCESS,
  FETCH_CONVOS_FAILURE,
  UPDATE_CONVO_READ,
} from '../redux-actions/convos.js';

const initialState = {
  convos: [],
  convo_id: null,
  loading: false,
  error: null,
  timestamp: 0,
  updateReq: false
};

export default function convoReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_CONVOS_BEGIN:

      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_CONVOS_SUCCESS:
      const a = {
        ...state,
        loading: false,
        convos: action.payload.convos,
        timestamp: new Date().getTime()
      };
      return a;

    case FETCH_CONVOS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        convos: [],
        timestamp: new Date().getTime()
      };

    case UPDATE_CONVO_READ:
      var newConvos = state.convos.slice();
      if (newConvos) {
        for (var i = 0; i < newConvos.length; i++) {
          const convo = newConvos[i];
          if (convo.convo_id == action.payload.convo_id) {
            convo.unread_messages = "0";
            break;
          }
        }
      }

      return {
        ...state,
        convos: newConvos
      };

    default:
      return state;
  }
}