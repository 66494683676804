import React from 'react';
import { Button } from 'reactstrap';
import { Card, CardImg, CardHeader, CardText, CardBody, CardTitle, CardSubtitle, InputGroup, Input, InputGroupAddon } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Helpers from '../Helpers.js';

class ConvoDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.modal,
      messages: this.props.messages,
      convo_id: this.props.convo_id,
      inputMsg: '',
      recipients: this.props.recipients,
      disableSend: true,
      message_id: null,
      err: '',
      updateConvos: this.props.updateConvos,
    };

    this.toggle = this.toggle.bind(this);
    this.sendMsg = this.sendMsg.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({modal: this.props.modal, convo_id: this.props.convo_id, recipients: this.props.recipients, messages: this.props.messages});
    this.forceUpdate();
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleChange(event) {
    const msg = document.getElementById('msgInput').value.trim();
    if (msg && msg.length > 2) {
      this.state.disableSend = false;
    } else {
      this.state.disableSend = true;
    }
    this.setState({inputMsg: msg, disableSend: this.state.disableSend});
  }

  sendMsg() {
    if (!this.state.inputMsg || this.state.inputMsg.length == 0)
      return;

    fetch(Helpers.getBaseURL() + '/fetch_messages.php', {
      method: 'POST',
      headers: Helpers.addHeaders(),
      body: Helpers.appendParams('convo_id=' + this.state.convo_id + '&type=2&message_id=' + this.state.message_id
       + '&message=' + escape(this.state.inputMsg))
    })
    .then(response => {
      var stuff = response.json();
      return stuff;
    })
    .then(data => {

      if (data.status == '1') {
        document.getElementById('msgInput').value = '';
        this.handleChange(null);

        this.setState({messages: data.output, err: ''});

        this.state.updateConvos(data.output);

      } else {
        this.setState({'err': data.message});
      }
      this.forceUpdate();

    })
    .catch(function(error) {
      this.setState({err: error});
      this.forceUpdate();
    }.bind(this));
  }

  render() {

    const stuff = [];
    const canRender = this.state.modal && this.state.messages && this.state.messages.length > 0;
    if (canRender) {
      
      for (var i = 0; i < this.state.messages.length; i++) {
        const curPos = i;
        const avatar = Helpers.loadAvatar(this.state.messages[i].user_avatar);
        stuff.push(
          <Card key={'card-'+i}>
            <CardHeader>
              <div className='row'>
                <div className='col-2'>
                  <img className='avatar-small' src={avatar} />
                </div>
                <div className='col-8'>
                  {this.state.messages[i].user_name}
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <div className='center text-small'>
                {this.state.messages[i].date_created}
              </div>
              <div className='row container'>
                <div className='col-10'>
                  {this.state.messages[i].message}
                </div>
              </div>
            </CardBody>
          </Card>
        );
      }
    }

    return (
      <div>
          <Dialog open={this.state.modal} onClose={this.toggle} className={this.props.className} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title"><b>Messages: {canRender && '#' + this.state.recipients}</b></DialogTitle>
            <DialogContent>
              <div>
                {stuff}
              </div>
              <div className='flex-container'>
                <input className='flex-4 input' id='msgInput' onChange={this.handleChange} />
                <Button className='flex-1' color='success' type='submit' disabled={this.state.disableSend} onClick={() => this.sendMsg()}>Send</Button>
              </div>
              <div className='err-text center' style={{'marginTop': '15px'}}>
                {this.state.err}
              </div>
            </DialogContent>
            <DialogActions>
              <div>
                <Button onClick={this.toggle}>OK</Button>
              </div>
            </DialogActions>
          </Dialog>          
      </div>
    );
  }


}

export default ConvoDetails;
