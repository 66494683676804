export var config = {
  apiKey: "AIzaSyCxzvTN2xFLhRSVWRlrraE5STUt0Aphd-0",
  authDomain: "commpost-75105.firebaseapp.com",
  databaseURL: "https://commpost-75105.firebaseio.com",
  projectId: "commpost-75105",
  storageBucket: "commpost-75105.appspot.com",
  messagingSenderId: "949456832404",
  appId: "1:949456832404:web:55b3e54d78c63855463181"
};

export const vapidKey = "BHG820RpRqvb4CUeICvbvVIU_VJpJcAKtFrVwthguRGt5EIVt0xF-_qNMD0RiMTORoXTJDT06QHySrcf7UiiQks";