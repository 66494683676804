import React from 'react';
import { Button } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TextField } from '@material-ui/core';
import Helpers from '../Helpers.js';

class LoginPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.modal,
      username: undefined,
      password: undefined,
      avatar: undefined,
      err: '',
      showLoading: [false],
      usernameValue: [localStorage.getItem('username')],
      passwordValue: [localStorage.getItem('password')],
      isFormValid: [false]
    };

    this.toggle = this.toggle.bind(this);

    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onUsernameChange = this.onUsernameChange.bind(this);
    this.checkFormValid = this.checkFormValid.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onLoginClick = this.onLoginClick.bind(this);
  }

  componentDidMount() {
    this.checkFormValid();
  }
    
  componentWillReceiveProps(props) {
    this.state.modal = this.props.modal;
  }

  toggle() {
    this.state.modal[0] = !this.state.modal[0];
    this.setState({
      modal: this.state.modal,
    });
  }

  onKeyPress(e) {
    if(this.state.modal[0] && e.charCode == 13){
      this.onLoginClick();
    }
  }

  onUsernameChange(event) {
    this.state.usernameValue[0] = event.target.value;
    
    this.checkFormValid();
  }

  onPasswordChange(event) {
    this.state.passwordValue[0] = event.target.value;

    this.checkFormValid();
  }

  checkFormValid() {
    if (this.state.passwordValue[0] && this.state.passwordValue[0].length > 3 && this.state.usernameValue[0].length > 2) {
      this.setState({'isFormValid': [true]});
    } else {
      this.setState({'isFormValid': [false]});
    }
  }
  
  onLoginClick() {
    this.setState({'err': '', 'showLoading': [true]});
    this.forceUpdate();

    const username = this.state.usernameValue.slice(0);
    const password = this.state.passwordValue.slice(0);

    fetch(Helpers.getBaseURL() + '/login.php', {
      method: 'POST',
      headers: Helpers.addHeaders(),
      body: 'web=1&machine_id=' + Helpers.getGUID() + '&username=' + username + '&password=' + password
    })
    .then(response => {
      var stuff = response.json();
      return stuff;
    })
    .then(data => {

      if (data.status == '1') {
        localStorage.setItem('user_id', data.user_id);
        localStorage.setItem('username', username);
        localStorage.setItem('password', password);
        localStorage.setItem('avatar', data.user_avatar);
        localStorage.setItem('user_status', data.user_status);
        localStorage.setItem('token', data.user_token);
        this.toggle();
        this.props.onSuccess(data.message);
      } else {
        this.setState({'err': data.message});
      }

      this.setState({'showLoading': [false]});
    })
    .catch(function(error) {
      this.setState({'err': error.message, 'showLoading': [false]});      
      this.forceUpdate();
      
    }.bind(this));
  }

  render() {
    return (
      <div onKeyPress={this.onKeyPress}>
        <Dialog open={this.state.modal[0]} onClose={this.toggle} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title"><b>LOGIN</b></DialogTitle>
          <DialogContent>
            <form noValidate autoComplete="off">
              <div>
                <TextField label="Email" value={this.state.usernameValue[0]} onChange={this.onUsernameChange} />
              </div>
              <div style={{'marginTop': '10px'}}>                
                <TextField label="Password" type="password" value={this.state.passwordValue[0]} onChange={this.onPasswordChange} />
              </div>
              <div className='err-text center'>
                {this.state.err}
              </div>
            </form>
          </DialogContent>
          <DialogActions>
            <div>
              <Button onClick={() => this.onLoginClick()} disabled={!this.state.isFormValid[0]} color='primary' style={{'marginTop': '15px'}} >
                LOGIN
              </Button>
            </div>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
  
}

export default LoginPopup;
