import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import Downloads from './pages/Downloads';
import Home from './pages/Home';
import Redirect from './pages/Redirect';
import Users from './pages/Users';
import Replays from './pages/Replays';
import Chat from './pages/Chat';
import CP from './pages/CP';
import Ladders from './pages/Ladders';
import Convos from './pages/Convos';
import Tournaments from './pages/Tournaments';
import Maps from './pages/Maps';
import TournamentDetails from './pages/TournamentDetails';
import StatsSheet from './pages/StatsSheet';
import Root from './components/Root';
import ErrorPage from './pages/ErrorPage';
import ThankYouPage from './pages/ThankYouPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Footer from './components/Footer';
import Helpers from './Helpers.js';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Subscribe from './pages/Subscribe';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Rubik',
      'sans-serif',
    ].join(','),
  },
});

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      store: this.props.store
    };
  }

  render() {
    return (
      <Provider store={this.props.store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <div>
              <Helmet>
                <title>KW-UPLOADS - Your ultimate portal for the latest C&C3 Kane's Wrath content</title>
                <meta name="description" content="Your ultimate portal for the latest C&C3 Kane's Wrath content. Home to the 1.02+ Patch, Map Packs, Replays, Tournaments and much more." />
                <meta name="keywords" content="Kane's Wrath, KW, C&C3, Command & Conquer 3, Command Post, CGF Uploads, 1.02+, maps, mods, replays, tournaments" />
              </Helmet>
  
              <Route path='/' component={Root} />
              <Switch>
                <Route exact path='/' component={Home} />              
                <Route exact path='/index.html' component={Home} />
                <Route exact path='/home' component={Home} />   
                <Route exact path='/donations' component={Home} />                
                <Route exact path='/redirect' component={Redirect} />          
                <Route exact path='/thank-you' component={ThankYouPage} />
                <Route path='/downloads/:type/:name' component={Downloads} />
                <Route path='/downloads' component={Downloads} />
                <Route path='/users' component={Users} />
                <Route path='/replays' component={Replays} />
                <Route path='/chat' component={Chat} />
                <Route path='/cp' component={CP} />    
                <Route path='/tournaments/:id/:tab' component={TournamentDetails} />
                <Route path='/tournaments/:id' component={TournamentDetails} />
                <Route path='/tournaments' component={Tournaments} />
                <Route path='/maps' component={Maps} />
                <Route path='/matches' component={Replays} />
                <Route path='/ladders' component={Ladders} />      
                <Route path='/convos' component={Convos} />
                <Route path='/subscribe' component={Subscribe} />
                <Route path='/inteldb' component={StatsSheet} />
                <Route path='/privacy-policy' component={PrivacyPolicy} />                
                <Route path='/:handle' component={ErrorPage} />
              </Switch>              
              <Footer patch='/' component={Footer} />
            </div>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    );
  }
}


export default App;
