import {
    PUSH_FAILURE,
  } from '../redux-actions/misc.js';

const initialState = {
    pushError: null,
};

export default function miscReducer(state = initialState, action) {
    switch(action.type) {
      case PUSH_FAILURE:
  
        return {
          ...state,
          pushError: action.payload.error
        };
  
      default:
        return state;
    }
  }