export const PUSH_FAILURE   = 'PUSH_FAILURE';

export const pushFailure = error => ({
  type: PUSH_FAILURE,
  payload: { error }
});

export function onPushFailure(error) {
  return dispatch => {
    dispatch(pushFailure(error));
  }
}
