import React, { Component } from 'react';
import ReplayDetails from '../components/ReplayDetails';
import Helpers from '../Helpers.js';
import SearchHeader from '../components/SearchHeader';
import ReplayComments from '../components/ReplayComments';
import UserDetailsPopup from '../components/UserDetailsPopup';
import Popup from '../components/Popup';
import { Progress } from 'reactstrap';
import { Helmet } from "react-helmet";

class Replays extends Component {

  constructor(props) {
    super(props);

    this.state = {
      replays: [],
      total_count: [0],
      page_cur: [0],
      page_max: [0],
      num_per_page: [0],
      page_goto: [''],
      search_text: '',
      selectedPlayer: null,
      showLoading: [true],
      err: [null],
      popupMsg: [''],
      showUserDetailsPopup: [false],
      showModal: [false],
      showCommentsModal: [false],
      comments: [],
      replay_id: [0],
      downloadUrl: null,
      is_data_loaded: [false],
      is_matches: this.props.location.pathname.toLowerCase().startsWith('/matches')
    };

    this.getReplayHtml = this.getReplayHtml.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onCommentsClick = this.onCommentsClick.bind(this);
    this.onDownloadClick = this.onDownloadClick.bind(this);
    this.onLikeClick = this.onLikeClick.bind(this);
    this.onPlayerClick = this.onPlayerClick.bind(this);
    this.updateReplayDetails = this.updateReplayDetails.bind(this);

    this.onNextClicked = this.onNextClicked.bind(this);
    this.onPrevClicked = this.onPrevClicked.bind(this);
    this.onPageGotoClicked = this.onPageGotoClicked.bind(this);
    this.onPageGotoChange = this.onPageGotoChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchClicked = this.onSearchClicked.bind(this);
  }

  getReplayHtml() {
    var a = [];
    if (this.state.replays) {
      for (var i = 0; i < this.state.replays.length; i++) {
        const stuff = (
          <div key={this.state.replays[i].id}>
            <ReplayDetails is_matches={this.state.is_matches} data={this.state.replays[i]} onDownloadClick={this.onDownloadClick} 
            onCommentsClick={this.onCommentsClick} onLikeClick={this.onLikeClick} onPlayerClick={this.onPlayerClick} />
          </div>
        );
        a.push(stuff);
      }
    }
    return a;
  }

  render() {
    const replays = this.getReplayHtml();
    
    var stuff;
    if (!this.state.err[0]) {
      stuff = this.state.showLoading[0] ? (<div className='w3-margin'><Progress bar animated value="100">Loading</Progress></div>) : replays;
    } else {
      stuff = (<span className='err-text-large'>{this.state.err[0]}</span>);
    }

    const titleTxt = !this.state.is_matches ? (
    <div>
      To upload your replays and to access replay downloads and comments, get <a href={Helpers.CP_URL}>Command Post</a> and register an account.
    </div>) : (
    <div>
     To have your matches recorded and look up more details and statistics, get <a href={Helpers.CP_URL}>Command Post</a> and register an account.
    </div>
    );

    return (
      <div className='Replays'>
        <Helmet>
          <title>KW-UPLOADS - Replays</title>
          <meta name="description" content="Browse and watch the latest Kane's Wrath replays" />
          <meta name="keywords" content="Kane's Wrath, KW, C&C3, Command & Conquer 3, Command Post, patches, maps, mods, replays, tournaments" />
        </Helmet>

        <SearchHeader onNextClicked={this.onNextClicked} onPrevClicked={this.onPrevClicked} num_per_page={this.state.num_per_page} total_count={this.state.total_count}
          page_cur={this.state.page_cur} page_max={this.state.page_max} page_goto={this.state.page_goto} onPageGotoClicked={this.onPageGotoClicked}
          onPageGotoChange={this.onPageGotoChange} onSearchChange={this.onSearchChange} onSearchClicked={this.onSearchClicked} />
        <div className='w3-margin'>
          {titleTxt}
        </div>
        <div className='w3-margin'>
          {stuff}
        </div>
        <UserDetailsPopup modal={this.state.showUserDetailsPopup} player={this.state.selectedPlayer} />
        <ReplayComments modal={this.state.showCommentsModal} replay_id={this.state.replay_id} comments={this.state.comments} />
        <Popup modal={this.state.showModal} title='Info' text={this.state.popupMsg} url={this.state.downloadUrl}  />
      </div>
    );
  }

  componentDidUpdate() {
    if (!this.state.is_data_loaded[0]) {
      this.fetchData();
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  static getDerivedStateFromProps(props, state) {
    const prevIsMatches = state.is_matches;
    state = {
      ...state,
      is_matches: props.location.pathname.toLowerCase().startsWith('/matches')
    }; 
    if (prevIsMatches != state.is_matches) {
      state.is_data_loaded[0] = false;
    }
    return state;
  }

  onPrevClicked() {
    if (this.state.page_cur[0] > 1)
      this.fetchData(this.state.page_cur[0] - 1);
  }

  onNextClicked() {
    if (this.state.page_cur[0] < this.state.page_max[0])
      this.fetchData(this.state.page_cur[0] + 1);
  }

  onPageGotoClicked() {
    this.fetchData(this.state.page_goto[0]);
  }
    
  onPageGotoChange(event) {
    this.state.page_goto[0] = event.target.value;
    this.setState({page_goto: this.state.page_goto});
  }

  onSearchChange(event) {
    this.state.search_text = event.target.value;
    this.setState({search_text: this.state.search_text});
  }

  onSearchClicked() {
    this.fetchData(this.state.page_cur[0]);
  }

  fetchData(pageTo = 0) {
    this.state.is_data_loaded[0] = true;
    this.state.showLoading[0] = true;

    const url = this.state.is_matches ? '/fetch_stats.php' : '/fetch_replays.php';

    fetch(Helpers.getBaseURL() + url, {
      method: 'POST',
      headers: Helpers.addHeaders(),
      body: Helpers.appendParams('page_cur=' + pageTo + '&search_text=' + this.state.search_text)
    })
    .then(response => {
      var stuff = response.json();
      return stuff;
    })
    .then(data => {
      //console.log(data);

      this.state.err[0] = '';
      this.setState({replays: [], err: this.state.err});

      if (data.status == '1') {
        this.state.page_cur[0] = data.page_cur;
        this.state.page_max[0] = data.page_max;
        this.state.page_goto[0] = '';
        this.state.num_per_page[0] = data.num_per_page;
        this.state.total_count[0] = data.total_count;
        this.state.search_text = data.search_text;
      } else {
        this.state.err[0] = data.message;
      }

      this.state.showLoading[0] = false;

      this.setState({replays: data.output, search_text: this.state.search_text, err: this.state.err, showLoading: this.state.showLoading});
    })
    .catch(function(error) {
      this.state.showLoading[0] = false;
      this.state.err[0] = error.message;
      this.setState({err: this.state.err, showLoading: this.state.showLoading});
    }.bind(this));
  }

  onPlayerClick(player) {
    if (player) {
      this.state.showUserDetailsPopup[0] = true;
      this.setState({showUserDetailsPopup: this.state.showUserDetailsPopup, selectedPlayer: player});
    }
  }

  onLikeClick(replay_id) {
    this.state.showLoading[0] = true;
    fetch(Helpers.getBaseURL() + '/fetch_replays.php', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: Helpers.appendParams('type=5&replay_id=' + replay_id)
    })
    .then(response => {
      var stuff = response.json();
      return stuff;
    })
    .then(data => {
      this.state.showLoading[0] = false;
      if (data.status == '1') {
        this.updateReplayDetails(data.output);
        this.setState({replays: this.state.replays});
      }
      this.state.showModal[0] = true;
      this.state.popupMsg[0] = data.message;
      this.setState({showLoading: this.state.showLoading, popupMsg: this.state.popupMsg, showModal: this.state.showModal, downloadUrl: null});
    })
    .catch(function(error) {
      this.state.showLoading[0] = false;
      this.state.showModal[0] = true;
      this.state.popupMsg[0] = error.message;
      this.setState({showLoading: this.state.showLoading, showModal: this.state.showModal, popupMsg: this.state.popupMsg});
    }.bind(this));
  }

  onDownloadClick(replay_id) {
    this.state.showLoading[0] = true;
    fetch(Helpers.getBaseURL() + '/fetch_replays.php', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: Helpers.appendParams('type=4&replay_id=' + replay_id)
    })
    .then(response => {
      var stuff = response.json();
      return stuff;
    })
    .then(data => {
      this.state.showLoading[0] = false;

      if (data.status == '1') {        
        for (var i = 0; i < data.output.length; i++) {
          const curItem = data.output[i];
          if (curItem.id == replay_id) {
            this.updateReplayDetails(data.output);
            this.state.showModal[0] = true;
            this.state.popupMsg[0] = 'Ready to download!';
            this.setState({replays: this.state.replays, popupMsg: this.state.popupMsg, showModal: this.state.showModal, downloadUrl: curItem.url});
            break;
          }
        }
      } else {
        this.state.showModal[0] = true;
        this.state.popupMsg[0] = data.message;
        this.setState({err: data.message, showModal: this.state.showModal, downloadUrl: null});
      }

      this.setState({showLoading: this.state.showLoading});
    })
    .catch(function(error) {
      this.state.showLoading[0] = false;
      this.state.showModal[0] = true;
      this.state.popupMsg[0] = error.message;
      this.setState({showLoading: this.state.showLoading, showModal: this.state.showModal, popupMsg: this.state.popupMsg});
    }.bind(this));
  }

  onCommentsClick(replay_id) {
    this.state.showLoading[0] = true;
    fetch(Helpers.getBaseURL() + '/fetch_replays_comments.php', {
      method: 'POST',
      headers: Helpers.addHeaders(),
      body: Helpers.appendParams('replay_id=' + replay_id)
    })
    .then(response => {
      var stuff = response.json();
      return stuff;
    })
    .then(data => {

      this.setState({comments: [], err: ''});

      if (data.status == '1') {
        this.state.showCommentsModal[0] = true;
        this.setState({comments: data.output, replay_id: [data.replay_id], showCommentsModal: this.state.showCommentsModal});
      } else {
        this.state.showModal[0] = true;
        this.state.popupMsg[0] = data.message;
        this.setState({showModal: this.state.showModal, popupMsg: this.state.popupMsg});
      }
      this.state.showLoading[0] = false;
      this.setState({showLoading: this.state.showLoading});
    })
    .catch(function(error) {
      console.log(error);
      this.state.showLoading[0] = false;
      this.state.showModal[0] = true;
      this.state.popupMsg[0] = error.message;
      this.setState({showModal: this.state.showModal, popupMsg: this.state.popupMsg, showLoading: this.state.showLoading});
    }.bind(this));
  }

  updateReplayDetails(data) {
    if (data && this.state.replays) {
      for (var i = 0; i < data.length; i++) {
        const newReplay = data[i];
        for (var j = 0; j < this.state.replays.length; j++) {
          const oldReplay = this.state.replays[j];
          if (oldReplay.id == newReplay.id) {
            oldReplay.likes = newReplay.likes;
            oldReplay.downloads = newReplay.downloads;
            oldReplay.comments = newReplay.comments;
          }
        } 
      }
    }
  }


}


export default Replays;
