import React, {component} from 'react';
import { Button } from 'reactstrap';

function PaypalDonateButton({url, text}) {

    function onDonateClick() {
        if (url) {
            window.open(url, '_blank');
        } else {
            window.open("https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=PWMFFZTHYPFN4&source=url", '_blank');
        }
    }

    const btnText = text ? text : 'DONATE!'

    return (
        <Button color='warning' onClick={onDonateClick} alt="PayPal – The safer, easier way to pay online!">{btnText}</Button>
    );
}

export default PaypalDonateButton;