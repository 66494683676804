import React, { Component } from 'react';
import './Users.css';
import UserDetails from '../components/UserDetails.js';
import UserDetailsPopup from '../components/UserDetailsPopup';
import SearchHeader from '../components/SearchHeader';
import Helpers from '../Helpers.js';
import { Progress } from 'reactstrap';

class Users extends Component {

  constructor(props) {
    super(props);

    this.state = {
      users: [],
      showLoading: [false],
      err: [''],
      showUserDetailsPopup: [false],
      selectedUser: null,
      total_active: [0],
      refTime: [null],

      total_count: [0],
      page_cur: [0],
      page_max: [0],
      num_per_page: [0],
      page_goto: [''],
      search_text: '',
    };

    this.onUserClick = this.onUserClick.bind(this);
    this.fetchUsers = this.fetchUsers.bind(this);

    this.onNextClicked = this.onNextClicked.bind(this);
    this.onPrevClicked = this.onPrevClicked.bind(this);
    this.onPageGotoClicked = this.onPageGotoClicked.bind(this);
    this.onPageGotoChange = this.onPageGotoChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchClicked = this.onSearchClicked.bind(this);
  }

  componentDidMount() {
    this.state.showLoading[0] = true;
    this.setState({showLoading: this.state.showLoading});

    this.fetchUsers();
  }

  fetchUsers(page=0) {
    fetch(Helpers.getBaseURL() + '/fetch_users.php', {
      method: 'POST',
      headers: Helpers.addHeaders(),
      body: Helpers.appendParams('type=0&page_cur=' + page + '&search_text=' + this.state.search_text)
    })
    .then(response => {
      var stuff = response.json();
      return stuff;
    })
    .then(data => {
      this.state.showLoading[0] = false;

      if (data.status == '1' && data.output) {
        this.state.users = data.output;
        if (data.output.length > 0) {
          this.state.err[0] = '';
        } else {
          this.state.err[0] = 'No users found.';
        }
        
        this.state.page_cur[0] = data.page_cur;
        this.state.page_max[0] = data.page_max;
        this.state.total_count[0] = data.total_count;
        this.state.num_per_page[0] = data.num_per_page;
        this.state.page_goto[0] = '';

        this.state.total_active[0] = data.active_count;

        this.state.refTime[0] = new Date();
      } else {
        this.state.err[0] = data.message;
      }

      this.setState({users: this.state.users, page_cur: this.state.page_cur, page_max: this.state.page_max, 
        total_count: this.state.total_count, total_active: this.state.total_active, num_per_page: this.state.num_per_page, page_goto: this.state.page_goto,
         showLoading: this.state.showLoading, err: this.state.err});

      this.forceUpdate();
    })
    .catch(function(error) {
      this.state.showLoading[0] = false;
      this.state.err[0] = error.message;
      this.setState({showLoading: this.state.showLoading, err: this.state.err});
    }.bind(this));
  }

  render() {
    const isLoading = this.state.showLoading[0];
    const stuff = [];
    var numActiveUsers = 0;
    if (!isLoading)
    {
      const searchHeader = (<SearchHeader onNextClicked={this.onNextClicked} onPrevClicked={this.onPrevClicked} num_per_page={this.state.num_per_page} total_count={this.state.total_count}
        page_cur={this.state.page_cur} page_max={this.state.page_max} page_goto={this.state.page_goto} onPageGotoClicked={this.onPageGotoClicked}
        onPageGotoChange={this.onPageGotoChange} onSearchChange={this.onSearchChange} onSearchClicked={this.onSearchClicked} />);

      if (this.state.users && this.state.users.length > 0) {
        for (var i = 0; i < this.state.users.length; i++) {
          const curPos = i;
          const user = this.state.users[curPos];
          const uid = 'userdetails-' + curPos;
          stuff.push(
            <div key={uid}>
              <UserDetails user={user} onUserClick={this.onUserClick} refTime={this.state.refTime} />
            </div>
          );
        }

        const numActiveUsers = this.state.total_active[0];

        return (
          <div>
            {searchHeader}
            <div className='Users w3-margin'>
              <div>
                To look up more user information, check the Users tab on <a href={Helpers.CP_URL}>Command Post</a>.
              </div>
              <br/>
              <div>
                {numActiveUsers} User(s) currently active
              </div>
              <div className='w3-margin'>
                {stuff}
              </div>
            </div>
            <UserDetailsPopup modal={this.state.showUserDetailsPopup} user={this.state.selectedUser} refTime={this.state.refTime} />
          </div>
        );
      } else {
        return (
          <div>
            {searchHeader}
            <div className='Users w3-margin'>
              <div className='err-text-large'>
              {this.state.err[0]}
              </div>
            </div>
          </div>
        );
      }
    } else {
      return (<div className='w3-margin'><Progress bar animated value="100">Loading</Progress></div>);
    }
  }

  onUserClick(user) {
    this.state.showUserDetailsPopup[0] = true;
    this.setState({selectedUser: user, showUserDetailsPopup: this.state.showUserDetailsPopup});
  }

  onNextClicked() {
    if (this.state.page_max > this.state.page_cur) {
      this.fetchUsers(this.state.page_cur[0] + 1);
    }      
  }

  onPrevClicked() {
    if (this.state.page_cur > 1) {
      this.fetchUsers(this.state.page_cur[0] - 1);
    }      
  }

  onPageGotoClicked()  {
    this.fetchUsers(this.state.page_goto);
  }

  onSearchClicked() {
    this.fetchUsers();
  }

  onPageGotoChange(event) {
    this.state.page_goto[0] = event.target.value;
    this.setState({page_goto: this.state.page_goto});
  }

  onSearchChange(event) {
    this.setState({search_text: event.target.value});
  }

}


export default Users;
