import React, { Component } from 'react';
import { Card, CardImg, CardHeader, CardText, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap';
import './ReplayDetails.css';
import './MatchDetails.css';
import Helpers from '../Helpers.js';


class ReplayDetails extends Component {

  constructor(props) {
    super(props);

    this.state = {
      replay: this.props.data,
      is_matches: this.props.is_matches,
      onCommentsClick: this.props.onCommentsClick,
      onDownloadClick: this.props.onDownloadClick,
      onLikeClick: this.props.onLikeClick,
      onPlayerClick: this.props.onPlayerClick
    };

    this.getTeams = this.getTeams.bind(this);
    this.getMappackName = this.getMappackName.bind(this);
  }

  getTeams(players) {
    const teams = [];
    for (var i = 0; i < this.state.replay.data.players.length; i++) {
      const curPlayer = this.state.replay.data.players[i];
      if (curPlayer.team == -1) {
        const team = {'id': -1, 'players': [curPlayer]};
        teams.push(team);
      } else {
        var teamFound = false;
        for (var j = 0; j < teams.length; j++) {
          if (teams[j].id == curPlayer.team) {
            teams[j].players.push(curPlayer);
            teamFound = true;
            break;
          }
        }
        if (!teamFound) {
          const team = {'id': curPlayer.team, 'players':[curPlayer]};
          teams.push(team);
        }
      }
    }
    return teams;
  }

  getPatchName() {
    if (this.state.replay.data.patches && this.state.replay.data.patches.length > 0) {
      if (this.state.replay.data.patches[0].version_name)
        return this.state.replay.data.patches[0].patch_display_name + ' ' + this.state.replay.data.patches[0].version_name;
      else {
        return this.state.replay.data.patches[0].patch_display_name;
      }
    }
  }

  getMappackName() {
   if (this.state.replay.data.mappacks && this.state.replay.data.mappacks.length > 0)  {
      if (this.state.replay.data.mappacks[0].version_name)
        return this.state.replay.data.mappacks[0].mappack_display_name + ' ' + this.state.replay.data.mappacks[0].version_name;
      else {
        return this.state.replay.data.mappacks[0].mappack_display_name;
      }
   }
  }

  render() {
    const stuff = '';
    if (!this.state.replay) {
      return (
        <div>
          Error!
        </div>
      );
    } else {
      // Create teams
      const teams = this.getTeams(this.state.replay.data.players);

      const teamStuff = [];
      for (var i = 0; i < teams.length; i++) {
        const stuff = [];
        for (var j = 0; j < teams[i].players.length; j++) {
          const p = teams[i].players[j];
          const faction = p.faction > 1 ? p.faction : p.rand_faction;
          const icon = Helpers.getFactionIcon(faction);
          const playerName = p.name;
          stuff.push(
            <div key={'player-' + j} className='replay-details-faction-div' onClick={() => this.state.onPlayerClick(p)}>
              <img className='replay-details-faction-icon' src={icon} />              
              <div className='replay-details-player-name'>
                {playerName}
              </div>
              <span>
              </span>
            </div>
          );
        }
        teamStuff.push(<div key={'team-' + i} className='replay-details-team'>{stuff}</div>);
      }

      const isMobile = Helpers.isMobile();
      const minimap_class = 'float ' + (this.state.replay.data.minimap_rev && this.state.replay.data.minimap_rev == true ? 'replay-details-minimap-rev' : 'replay-details-minimap');
      const duration = Helpers.toMMSS(Math.round(this.state.replay.data.duration / 15));
      const mappack_name = this.getMappackName();
      const patch_name = this.getPatchName();
      const match_date = Helpers.convertDate(this.state.replay.data.match_date);
      
      if (!this.state.replay.minimap_url) {
        this.state.replay.minimap_url = require('../res/unknown_map.png');
      }

      const upload_date = !this.state.is_matches ? Helpers.convertDate(this.state.replay.date_uploaded) : '';
      const uploader_avatar = !this.state.is_matches ? Helpers.loadAvatar(this.state.replay.uploader_avatar) : '';

      if (!this.state.is_matches) { // ========== REPLAYS ===========
        return (
          <div className='ReplayDetails'>
            <Card>
              <CardHeader>
                <div className='row'>
                  <div className='col-3 replay-details-id'>
                    #{this.state.replay.id}
                  </div>
                  <div className='col-9 replay-details-title'>
                    {this.state.replay.title}
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className='row replay-details-action-div'>
                  <div className='col-md-3 col-xs-12'>
                    <Button color='primary' size='sm' onClick={() => this.props.onDownloadClick(this.state.replay.id)} className='replay-details-action-btn'>
                      Downloads ({this.state.replay.downloads})
                    </Button>
                  </div>
                  <div className='col-md-3 col-xs-12'>
                    <Button color='warning' size='sm' onClick={() => this.props.onLikeClick(this.state.replay.id)} className='replay-details-action-btn'>
                      Likes ({this.state.replay.likes})
                    </Button>
                  </div>
                  <div className='col-md-3 col-xs-12'>
                    <Button color='danger' size='sm' onClick={() => this.state.onCommentsClick(this.state.replay.id)} className='replay-details-action-btn'>
                      Comments ({this.state.replay.comments})
                    </Button>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-4 col-xs-6'>
                    <div className='row'>
                      <img className={minimap_class} src={this.state.replay.data.minimap_url} />
                      <div className='float replay-details-map-div'>
                        <div className='replay-details-map-name'>{this.state.replay.data.map_name}</div>
                        <div className='replay-details-mappack-name'><a href='/downloads'>{mappack_name}</a></div>
                        <div className='replay-details-patch-name'><a href='/downloads'>{patch_name}</a></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4 col-xs-6 replay-details-teams-div'>
                      {teamStuff}
                  </div>
                  <div className='col-md-4 col-xs-6'>
                    <div className='replay-details-uploader-div'>
                      <div className='replay-details-uploader-txt'>Uploader:</div>
                      <div className='replay-details-uploader-name'>{this.state.replay.uploader_name}</div>
                      <img className='replay-details-avatar' src={uploader_avatar} />
                    </div>
                  </div>
                  <div className='col-md-4 col-xs-6'>
                    <div className='replay-details-txt'>Match Duration:</div>
                    <div className='replay-details-name'>{duration}</div>
                  </div>
                  <div className='col-md-4 col-xs-6 center'>
                    <div>
                      <div className='replay-details-txt'>Match Date:</div>
                      <div className='replay-details-name'>{match_date}</div>
                    </div>
                  </div>
                  <div className='col-md-4 col-xs-6'>
                    <div className='replay-details-uploader-div'>
                      <div className='replay-details-txt'>Date Uploaded:</div>
                      <div className='replay-details-name'>{upload_date}</div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        );
      } else { // =========== MATCHES ==============
        const matchResults = [];
        if (this.state.replay.nicks) {
          for (var i = 0; i < this.state.replay.nicks.length; i++) {
            const uid = 'match-nick-' + i;
            const nick = this.state.replay.nicks[i].nick_name;
            const is_winner = this.state.replay.nicks[i].is_winner;
            var pts = this.state.replay.nicks[i].pts;
            var ptsClass = 'match-result-na';
            if (is_winner == 1) {
              pts = '+' + pts;
              ptsClass = 'match-result-winner';
            } else if (is_winner == 2) {
              pts = '-' + pts;
              ptsClass = 'match-result-loser';
            } else {
              pts = 'N/A';
            }
            matchResults.push(
              <div key={uid} className='match-result-item'>
                <span>{nick}</span><span className={ptsClass}>{pts}</span>
              </div>                                
            );
          }
        }

        return (
          <div className='ReplayDetails'>
            <Card>
              <CardHeader>
                <div className='row'>
                  <div className='col-3 replay-details-id'>
                    #{this.state.replay.id}
                  </div>
                  <div className='col-9 replay-details-title'>
                    {this.state.replay.title}
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className='row replay-details-action-div'>

                </div>
                <div className='row'>
                  <div className='col-md-4 col-xs-6'>
                    <div className='row'>
                      <img className={minimap_class} src={this.state.replay.data.minimap_url} />
                      <div className='float replay-details-map-div'>
                        <div className='replay-details-map-name'>{this.state.replay.data.map_name}</div>
                        <div className='replay-details-mappack-name'><a href='/downloads'>{mappack_name}</a></div>
                        <div className='replay-details-patch-name'><a href='/downloads'>{patch_name}</a></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4 col-xs-6 replay-details-teams-div'>
                      {teamStuff}
                  </div>
                  <div className='col-md-4 col-xs-6'>
                    <div className='replay-details-uploader-div'>
                      <div className='match-results-txt'>Results:</div>
                      {matchResults}
                    </div>
                  </div>
                  <div className='col-md-4 col-xs-6'>
                    <div className='replay-details-txt'>Match Duration:</div>
                    <div className='replay-details-name'>{duration}</div>
                  </div>
                  <div className='col-md-4 col-xs-6 center'>
                    <div>
                      <div className='replay-details-txt'>Match Date:</div>
                      <div className='replay-details-name'>{match_date}</div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        );        
      }
    }
  }

}


export default ReplayDetails;
