import React, { Component } from 'react';
import './ErrorPage.css'
import Helpers from '../Helpers.js';

class ErrorPage extends Component {

  componentDidMount () {
  }

  render() {
    return (
      <div className='error-div'>
        PAGE NOT FOUND!
        <br />
        <img className='error-img' src={require('../res/ionc.webp')} />
      </div>
    );
  }
}


export default ErrorPage;
