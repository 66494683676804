import React, { Component } from 'react';
import { Card, CardImg, CardHeader, CardText, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap';
import './ReplayDetails.css';
import Helpers from '../Helpers.js';


class MapDetails extends Component {

  constructor(props) {
    super(props);

    this.state = {
      map: this.props.data,
      onCommentsClick: this.props.onCommentsClick,
      onDownloadClick: this.props.onDownloadClick,
      onLikeClick: this.props.onLikeClick,
      onMapClick: this.props.onMapClick
    };
  }

  render() {
    const stuff = '';
    if (!this.state.map) {
      return (
        <div className='err-text-large'>
          Error!
        </div>
      );
    } else {

      const map = this.state.map;

      const isMobile = Helpers.isMobile();
      const minimap_class = 'float ' + (this.state.map.minimap_rev && this.state.map.minimap_rev == true ? 'replay-details-minimap-rev' : 'replay-details-minimap');

      
      if (!map.minimap_url) {
        map.minimap_url = require('../res/unknown_map.png');
      }

      const upload_date = Helpers.convertDate(this.state.map.created_at);

      const uploader_name = this.state.map.uploader_name;
      const uploader_avatar = Helpers.loadAvatar(this.state.map.uploader_avatar);

      const id = 'map-' + map.id;
      const desc = (
        <span dangerouslySetInnerHTML={{__html: Helpers.linkifyText(map.desc)}} />          
      );

      return (
        <div key={id} className='row file-details-main container'>
          <div className='col-lg-3 col-xs-3 align-self-center'>
            <img className={minimap_class} src={map.minimap_url} />
          </div>
          <div className='col-lg-6 col-xs-6'>
            <div className='file-details-name center'>
              {map.display_name}
            </div>
            <div className='file-details-desc'>              
              {desc}
            </div>
          </div>
          <div className='col-lg-3 col-xs-3 align-self-center' style={{'marginTop': '10px'}}>
            {/*
            <div className='center' style={{'marginTop': '15px'}}>
              <Button color='info' onClick={() => this.state.onMapClick(map)}>MORE INFO</Button>
            </div>
            */}
            <div className='center' style={{'marginTop': '15px'}}>
              <Button color='success' onClick={() => this.state.onDownloadClick(map)}>DOWNLOAD</Button>
            </div>
            <div className='center' style={{'marginTop': '10px'}}>
              <div>
                Uploaded:
              </div>
              <div>
                {upload_date}
              </div>
            </div>
          </div>
        </div>
      );
    }    
  }

}


export default MapDetails;
