// ButtonContainer.js
import React from 'react';
import './CustomButton.css'; // Import your CSS file here

const CustomButton = ({color, text, icon, onClick, style}) => {
    const iconDiv = icon ? (<img src={icon}></img>) : null
    const btnClass = color == 'success' ? 'custom-btn custom-btn-green' : 'custom-btn'
    return (
        <div className={btnClass} style={style} onClick={onClick}>
        {iconDiv}
        <span className='ml-2'>
            {text}
        </span>
      </div>
    );
};

export default CustomButton;