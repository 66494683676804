import React, { Component } from 'react';
import ConvoDetails from '../components/ConvoDetails';
import Helpers from '../Helpers.js';
import { Button, ModalBody, ModalFooter, ModalHeader, Modal, Card, CardImg, CardHeader, CardText, CardBody, CardTitle, CardSubtitle, InputGroup, Input, InputGroupAddon } from 'reactstrap';
import SearchHeader from '../components/SearchHeader';
import ReplayComments from '../components/ReplayComments';
import Popup from '../components/Popup';
import './Convos.css';
import { Progress } from 'reactstrap';

import { fetchConvos, updateConvoRead, setConoAsRead } from "../redux-actions/convos.js";
import { connect } from "react-redux";

const ConvoItem = (props) => {
  
  const recipients = props.recipients;

  const last_read = "Last Message: " + Helpers.convertDate(props.obj.last_read);
  const last_message = 
  (
    <span className='text-pre' dangerouslySetInnerHTML={{__html: Helpers.linkifyText(props.obj.last_message.message)}} />          
  );
  const last_avatar = Helpers.loadAvatar(props.obj.last_message.user_avatar);
  const last_user = props.obj.last_message.user_name;
  const unread_count = (parseInt(props.obj.unread_messages) + 0);

  var notif_dots = null;
  if (unread_count > 0)
    notif_dots = (<div className='circle-small convo-btn'><span style={{'verticalAlign': 'middle'}}>{unread_count}</span></div>);  

  return (
    <Card>
      <CardHeader>
        <div className='flex-container'>
          <div>
            <span>With </span>
            <span className='wt-500'>{recipients}</span>
            <div>
              {last_read}
            </div>
          </div>
          {notif_dots}
        </div>
      </CardHeader>
      <CardBody>
        <div className='row convo-body-div'>
          <div className='convo-user-div col-md-2 col-sm-12'>
            <div>              
              <div className='convo-user-name-div'>{last_user}</div>
              <img src={last_avatar} className='convo-user-avatar-img' />
            </div>
          </div>
          <div className='convo-content-div container col-md-10 col-sm-12'>
            <div className='convo-message-div container'>
              {last_message}
            </div>
            <div className='convo-btn-div'>
              <Button className='convo-btn' color='primary' onClick={props.onClick}>Read</Button>
            </div>
          </div>          
        </div>
      </CardBody>
    </Card>

  );
};

class Convos extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      showDetails: false,
      messages: [],
      convo_id: null,
      recipients: null
    };

    this.fetchMessages = this.fetchMessages.bind(this);
    this.updateConvos = this.updateConvos.bind(this);
  }

  fetchMessages(convo_id, recipients) {
    fetch(Helpers.getBaseURL() + '/fetch_messages.php',  {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',      
      },
      body: Helpers.appendParams('convo_id=' + convo_id)
    })
    .then(response => {
      var stuff = response.json();
      return stuff;
    })
    .then(data => {
      var show = false;
      var messages = [];
      var err = '';
      if (data.status == "1") {
        messages = data.output;
        show = true;
      } else {
        err = data.message;
      }
      this.setState({messages: messages, convo_id: convo_id, recipients: recipients, showDetails: show, error: err, showLoading: false});
      this.forceUpdate();

      console.log('fetch messages >>>>>>>>>>>');
      this.props.dispatch(setConoAsRead(convo_id)); 
    })
    .catch(function(error) {
        var err = error;
        this.setState({error: err, showLoading: false});
    }.bind(this));
  }

  updateConvos(messages) {
    this.setState({messages: messages});
    this.props.dispatch(fetchConvos());
  }

  render() {
    const { convosErr, convos } = this.props;

    var convosHtml = [];
    if (convos && convos.length > 0) {
      for (var i = 0; i < convos.length; i++) {
        const curPos = i;
        const convoObj = convos[curPos];
        const uid = 'convo-' + curPos;
        var recipients = "";
        for (var j = 0; j < convoObj.recipients.length; j++) {
          if (convoObj.recipients[j].id != localStorage.getItem('user_id')) {
            if (recipients == "")
              recipients = convoObj.recipients[j].name;
            else
              recipients += ", " + convoObj.recipients[j].name;
          }
        }
        const recipients1 = recipients;        
        convosHtml.push(
          <div key={uid} className='w3-margin'>            
            <ConvoItem onClick={() => this.fetchMessages(convoObj.convo_id, recipients1)} recipients={recipients} obj={convoObj} />
          </div>
        );
      }
    }

    const stuff = this.state.showLoading ? (<div className='w3-margin'><Progress bar animated value="100">Loading</Progress></div>) : convosHtml;

    if (this.state.showDetails) {

    }

    return (
      <div className='Convos'>
        <div>
          {stuff}
        </div>
        <ConvoDetails modal={this.state.showDetails} convo_id={this.state.convo_id} recipients={this.state.recipients} updateConvos={this.updateConvos} messages={this.state.messages} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    convos: state.convos.convos,
    err: state.convos.error,
    convosTimestamp: state.convos.timestamp,
    showLoading: state.convos.loading
  }
};

export default connect(mapStateToProps)(Convos);